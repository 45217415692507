import { CacheLocation } from "@auth0/auth0-react";

declare const window: any;

const AUTH0_CACHE_LOCATION: CacheLocation | undefined = "localstorage";
const config = window.ENV.oktaConfig;

const auth0Config = {
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: AUTH0_CACHE_LOCATION,
  useRefreshTokens: true,
  scope: config.scope,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

export { auth0Config };
