import React from 'react';
import { Button } from 'react-bootstrap';
import SortButton from '../utils/SortableButton/SortButton';
import * as FDS from '@arctravel/react-fds/lib';

declare const window: any;

interface TabelProps {
    headers: any,
    sortKey: string,
    sortOrder: any,
    onSort: any,
    search: boolean,
    onChange: any,
    filterIp: any,
}

const SearchSortHeader = (props: TabelProps) => {

    const { headers, sortKey, search, onSort, sortOrder, onChange, filterIp } = props
    const alt: any = { "0": "Private", "2": "Shared", "ZERO": "No Records" }

    return (
        <thead>
            <tr>
                {headers?.map((row: any) => {
                    return (
                        <SortButton
                            key={row.key}
                            sortOrder={sortOrder}
                            columnKey={row.key}
                            sortKey={sortKey}
                            columnHeader={row.label}
                            onClick={() => row.key !== "action" && onSort(row.key)}
                        />
                    )
                })}
            </tr>

            {search && <tr>
                {headers?.map((row: any, ri: number) => {
                    let ele: any = null;
                    switch (row.filter?.type) {
                        case "date":
                            ele = (
                                <FDS.FDSDatePicker
                                    startDate={[filterIp[row.key]?.startDate || FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() - 30)))]}
                                    endDate={[filterIp[row.key]?.endDate || FDS.toMMDDYYYY(new Date())]}
                                    minDate={FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() - 30)))}
                                    maxDate={FDS.toMMDDYYYY(new Date())}
                                    selectsRange={true}
                                    onChange={(data) => { onChange((prev: any) => { prev[row.key] = data; return { ...prev } }); }} />
                            )
                            break;
                        case "select":
                            ele = (
                                <select className="form-select" aria-label={`select ${row.key}`} value={filterIp[row.key] || ""} onChange={(ev) => onChange((prev: any) => {
                                    prev[row.key] = ev.target.value;
                                    return { ...prev };
                                })}>
                                    <option value="">-- Select --</option>
                                    {row?.filter?.options?.sort((a: string, b: string) => {
                                        const titleA = window.ENV.props.reportTitles[a]?.shortTitle || window.ENV.props.reportTitles[a]?.mainTitle;
                                        const titleB = window.ENV.props.reportTitles[b]?.shortTitle || window.ENV.props.reportTitles[b]?.mainTitle;
                                        return titleA <= titleB ? -1 : 1
                                    }).map((op: any, i: number) => {
                                        const title = window.ENV.props.reportTitles[op]?.shortTitle || window.ENV.props.reportTitles[op]?.mainTitle;
                                        const child = window.ENV.props.reportTitles[op]?.childTitle;
                                        return <option key={i} value={op}>{(title ? title + (child ? " - " + child : "") : title) || op?.replace(/\b(?:ZERO|0|2)\b/gi, (matched: any) => { return alt[matched] })}</option>
                                    })}
                                </select>
                            )
                            break;
                        case "clear":
                            ele = (<Button variant='secondary' onClick={() => { onChange({}) }}>Clear</Button>);
                            break;
                        case "text":
                            ele = (<input type="text" value={filterIp[row.key] || ""} placeholder='Type to filter'
                                onChange={(ev) => onChange((prev: any) => {
                                    prev[row.key] = ev.target.value; return { ...prev };
                                })} />)
                            break;
                        default:
                            break;
                    }

                    return (
                        <th key={ri}>{ele}</th>
                    );
                })}
            </tr>}
        </thead>
    )
}

export default SearchSortHeader