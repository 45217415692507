declare const window: any;

const GLOBALDATA = "GLOBAL"
const airlineEntity = "AIRLINE"
const financialEntity = "THIRDPARTY-FINANCIAL"

export const OnDTemplate: any = {
    reportName: "OND_DEMAND",
    //info: "The following provides processing statistics, by calendar month, for the last three years plus the current year-to-date. If you have questions, please contact your account manager.",
    lists: [{
        label: "Query Filters",
        colorName: "",
        wraps: [{
            name: "Trip Origin",
            showName: true,
            excludable: true,
            type: 'option',
            condition: 'AND',
            requiredGroup: 1,
            fields: [{
                name: "org",
                tab: "Airport",
                label: "By Airport (s)",
                rLabel: "Origin Airport Codes",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                color: "ap",
                minChar: 2,
                show: true,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.airport,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                placeholder: "Enter Origin(s)"
            }, {
                name: "o_region",
                tab: "Region",
                label: "By Region/State(s)",
                rLabel: "Trip Origin Region",
                type: "tree",
                restrictTCs: true,
                value: [],
                labelKey: "name",
                codeKey: "code",
                color: "r",
                minChar: 2,
                show: false,
                disableFor: { entity: [] },
                url: window.ENV.references.region,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                placeholder: "Enter Origin(s)"
            }, {
                name: "o_city_cd",
                tab: "City",
                label: "By City(s)",
                rLabel: "Trip Origin City",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                color: "c",
                minChar: 1,
                show: false,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.city,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                placeholder: "Enter Origin(s)"
            }]
        }, {
            name: "Trip Destination",
            showName: true,
            type: 'option',
            condition: 'AND',
            excludable: true,
            requiredGroup: 1,
            fields: [{
                name: "dst",
                tab: "Airport",
                label: "By Airport(s)",
                rLabel: "Destination Airport Codes",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 2,
                color: 'ap',
                show: true,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.airport,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                placeholder: "Enter Destination(s)"
            }, {
                name: "d_region",
                tab: "Region",
                label: "By Region/State(s)",
                rLabel: "Trip Destination Region",
                type: "tree",
                restrictTCs: true,
                value: [],
                labelKey: "name",
                codeKey: "code",
                minChar: 2,
                color: 'r',
                show: false,
                disableFor: { entity: [] },
                url: window.ENV.references.region,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                placeholder: "Enter Destination(s)"
            }, {
                name: "d_city_cd",
                tab: "City",
                label: "By City(s)",
                rLabel: "Trip Destination City",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 1,
                color: 'c',
                show: false,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.city,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                placeholder: "Enter Destination(s)"
            }]
        }, {
            name: "Connect Points",
            showName: true,
            type: 'option',
            condition: 'AND',
            excludable: true,
            fields: [{
                name: "connect_points",
                tab: "By Airport",
                label: "By Airport(s)",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                color: "ap",
                minChar: 2,
                show: true,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.airport,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                placeholder: "Enter Connection(s)"
            }, {
                name: "connect_points_region",
                tab: "Region",
                label: "By Region/State(s)",
                rLabel: "Trip Destination Region",
                type: "tree",
                restrictTCs: true,
                value: [],
                labelKey: "name",
                codeKey: "code",
                minChar: 2,
                color: 'r',
                show: false,
                disableFor: { entity: [] },
                url: window.ENV.references.region,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                placeholder: "Enter Connection(s)"
            }, {
                name: "connect_points_city_cd",
                tab: "City",
                label: "By City(s)",
                rLabel: "Trip Destination City",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 1,
                color: 'c',
                show: false,
                disableFor: { entity: [financialEntity] },
                url: window.ENV.references.city,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                placeholder: "Enter Connection(s)"
            }]
        }, {
            name: "countriesOfSale",
            showName: false,
            fields: [{
                name: "pos_country",
                label: "Countries Of Sale",
                rLabel: "Countries of Sale",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "name",
                codeKey: "code",
                minChar: 0,
                disabled: true,
                enableFor: { dataSource: GLOBALDATA },
                show: true,
                url: window.ENV.references.country,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]')
            }]
        }, {
            name: "Date Information",
            help: "",
            isTitle: true,
            showName: false,
            fields: []
        }, {
            name: "date_type",
            showName: false,
            fields: [{
                name: "date_type",
                label: "Date Type",
                help: "Select either the purchase or travel type to correspond with the date period below.",
                type: "select",
                noSelect: true,
                value: 'travel_date',
                options: [
                    { key: "travel_date", value: "Travel Date(s)", checked: true },
                    { key: "purchase_date", value: "Purchase Date(s)", checked: true }
                ]
            }]
        }, {
            name: "Report Period",
            help: "FROM date should be previous or current to the TO date",
            showName: true,
            type: 'option',
            condition: 'OR',
            fields: [{
                name: "month_report_period",
                tab: "By Month",
                label: "By Month or Range",
                type: "monthYearPicker",
                selectsRange: true,
                startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                show: true
            }, {
                name: "quarter_report_period",
                tab: "By Quarter",
                label: "By Quarter or Range",
                type: "quarterPicker",
                selectsRange: true,
                startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                show: false
            }, {
                name: "summary_report_period",
                tab: "12M Sum",
                label: "12 Month Summary Ending",
                type: "monthYearPicker",
                startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                endDate: [''],
                show: false
            }, {
                name: "compare_m_report_period",
                tab: "Compare(M)",
                label: "Compare Months",
                compare: true,
                type: "monthYearPicker",
                selectsRange: true,
                startDate: [
                    (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                    (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                ],
                endDate: [
                    (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                    (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                ],
                show: false
            }, {
                name: "compare_q_report_period",
                tab: "Compare(Q)",
                label: "Compare Quarters",
                compare: true,
                type: "quarterPicker",
                selectsRange: true,
                startDate: [
                    "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                    "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                ],
                endDate: [
                    "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                    "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                ],
                show: false
            }, {
                name: "compare_y_report_period",
                tab: "Compare(Y)",
                label: "Compare 2 Years",
                compare: true,
                type: "yearPicker",
                startDate: [(new Date().getFullYear() - 1) + "", new Date().getFullYear() + ''],
                endDate: [],
                show: false
            }]
        }, {
            name: "Service Class",
            showName: false,
            fields: [{
                name: "cabin_class",
                label: "Service Class",
                type: "typeahead",
                matchedOnly: true,
                selectAll: true,
                value: [],
                minChar: "0",
                labelKey: "value",
                codeKey: "key",
                options: [
                    { key: "First Class", value: "First Class", checked: true },
                    { key: "Business Class", value: "Business Class", checked: true },
                    { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                    { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                    { key: "Other Classes", value: "Other Classes", checked: true }
                ],
                placeholder: "Enter Service Class(s)"
            }]
        }, {
            name: "Transaction Type",
            showName: false,
            fields: [{
                name: "transaction_code",
                label: "Transaction Type",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                minChar: "0",
                labelKey: "value",
                codeKey: "key",
                selectAll: true,
                options: [
                    { key: "E", value: "Exchange", checked: true },
                    { key: "O", value: "Old Ticket", checked: true, enableFor: { entity: [airlineEntity] } },
                    { key: "V", value: "Void", checked: true, enableFor: { dataSource: GLOBALDATA } },
                    { key: "R", value: "Refund", checked: true },
                    { key: "I", value: "Sales", checked: true }
                ],
                placeholder: "Enter Transaction Type(s)"
            }]
        }]
    }],
    chartsLists: [
        /*{
            label: "",
            wraps: [
                {
                    name: "creditcash",
                    label: "Credit",
                    showName: false,
                    fields: [{
                        name: "ondDemand",
                        label: "O&D Demand",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "BubbleChart",
                        semicircle: true,
                        showTotal: true,
                        totalText: "Total",
                        theme: { colors: ['#189BB0', "#75E6DA", "#D4F1F4"] },
                        keys: {
                            label: "org",
                            value: "nights_stayed",
                            symbol: "",
                            sort: ""
                        },
                        spacing: 4
                    }, {
                        name: "airportDemand",
                        label: "Airport Demand",
                        help: "",
                        type: "BubbleChart",
                        labels: { x: "Sales", y: "Credit Card" },
                        keys: {
                            label: "dst",
                            value: "nights_stayed",
                            symbol: "",
                            sort: ""
                        },
                        spacing: 4
                    }, {
                        name: "airlineDemand",
                        label: "Airline Demand",
                        help: "",
                        type: "BubbleChart",
                        labels: { x: "Sales", y: "Credit Card" },
                        keys: {
                            label: "trip_dom_rbkd",
                            value: "miles",
                            symbol: "",
                            sort: ""
                        },
                        spacing: 4
                    }]
                }
            ]
        }*/
    ]
};

