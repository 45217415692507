import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Container, Row, Col, Dropdown, Alert, ListGroup, Button, Table } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { templates, refreshActivity, processResponse } from '../utils';
import SaveTemplate from '../modals/SaveTemplate';
import { getHistoryAction, removeHistoryAction, downloadReport, getAllSavedTemplatesAction, getTemplateAction } from '../../store/actions';
import { BI } from '../bi';
import { ARCCIDataStatus } from '../otherReports/arcci/ARCCIDataStatus';

import {FDSInfiniteTable} from '../utils/FDSInfiniteTable';

import './Hub.scss';
import Feedback from '../modals/Feedback';
import SearchSortHeader from './SearchSortHeader';

declare const window: any;
const buttons = ["adhoc", "sched", "EXTERNAL_EVENT"]
export const HubHistory = () => {
    const params: any = useParams();
    const [id, setId] = useState(params.id);
    const [filterIp, setFilterIp]: any = useState({});

    const statusLogo: any = {
        "Success": "fds-glyphs-checkmark3",
        "Failed": "fds-glyphs-warning",
        "ZERO": "fds-glyphs-file-minus",
        "Processing": "fds-glyphs-clock3",
        "Exporting": "fds-glyphs-folder-upload3",
        "Max Col Limit": "fds-glyphs-warning",
        "Max Data Limit": "fds-glyphs-files-empty",
    }

    const statusColor: any = {
        "Success": "success",
        "Failed": "danger",
        "ZERO": "info",
        "Processing": "warning",
        "Exporting": "warning",
        "Max Col Limit": "danger",
        "Max Data Limit": "danger"
    }

    const historyNav = useHistory();
    const savedAllTemplatesRef = useSelector((state: any) => state.savedAllTemplatesRef);
    const [savedTemplates, setSavedTemplates]: any = useState([...savedAllTemplatesRef]);
    const selectedTemplate = { template_name: "" };
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [vTemplate, setVTemplate]: any = useState({});
    const [data, setData]: any = useState({});

    const [history, setHistory] = useState([]);
    const [tIp, setTIp]: any = useState({});
    const [showIndex, setShowIndex] = useState([-1]);

    const historyRef: any = useSelector((state: any) => state.historyRef);

    const dispatch = useDispatch();
    const [arrowButton, setArrowButton] = useState(buttons[0]);

    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [cust, setCust] = useState(customersRef.customer);

    const defaultConfig = useMemo(() => {
        return {
            apiUrl: window.ENV.reportsDomain,
            token: localStorage.getItem("bearer") + '',
            data: {},
            pageRecordCount: 250,
            showTooltips: true,
            compacted: true,
            columns: [],
            borderedHeader: true,
            header: {},
            headerOnly: false,
            sort: true,
            pivote: {
                rows: {
                    count: 0,
                    color: "info"
                },
                cols: {
                    count: 0,
                    color: "warning"
                }
            }
        }
    }, []);

    const [config, setConfig]: any = useState(defaultConfig);

    const tableChanged = (response: any) => {
        console.log("tableChanged", response);
        dispatch({ type: "LOADING", data: "" });
    }

    const removeHistory = (reportName: string) => {
        dispatch(removeHistoryAction(reportName))
    }

    const rePeriodType = async (hObj: any, type: string) => {
        // object ref if sche template is deleted
        const allPeriodType = Object.entries(hObj.template).filter(([key]) => key.includes("_period"))

        if (allPeriodType.length > 0) {
            type === "load" && await dispatch(await getTemplateAction(hObj.template?.Id, { ...hObj.template }));
            type === "save" && setTIp({ ...hObj });
        } else {
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Report History has been corrupted, please try fresh query` } });
        }
    }

    const saveTemplate = (hObj: any) => {
        refreshActivity();
        rePeriodType(hObj, "save")
        setShowTemplateModal(true);
    }

    const reRun = (hObj: any) => {
        // scheduled objects
        if (hObj.template?.Id) {
            rePeriodType(hObj, "load")
        } else {
            // adhoc objects
            dispatch({ type: "RERUN_TEMPLATE", data: { ...hObj.template } });
        }
        historyNav.push('/reports/' + hObj.reportType);
    }

    useEffect(() => {
        refreshActivity();
        setTIp(params.id);
        // Set tab to Sched or ADHOC based on the report type notification
        if (params.id !== "0") {
            setArrowButton(params.id.indexOf(buttons[0].toUpperCase()) > 0 ? buttons[0] : buttons[1])
        }
    }, [params]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(getAllSavedTemplatesAction(true));
        }, 500);
    }, [dispatch, params.id, customersRef.viewAs.org]);

    useEffect(() => {
        dispatch({ type: "REPORT_IP", data: {} });
        if (
            (params.id === "0") ||
            (params.id?.toUpperCase()?.indexOf("_ADHOC_") > 0 && arrowButton?.toUpperCase() === "ADHOC") ||
            (params.id?.toUpperCase()?.indexOf("_SCHED_") > 0 && arrowButton?.toUpperCase() === "SCHED") ||
            (params.id?.toUpperCase()?.indexOf("_EXTERNAL_EVENT_") > 0 && arrowButton?.toUpperCase() === "EXTERNAL_EVENT")
        ) {
            dispatch(getHistoryAction(arrowButton.toUpperCase()));
        }
        if (params.id !== "0") {
            dispatch({ type: "REMOVE_NOTIFICATION", data: params.id });
        }
    }, [dispatch, arrowButton, params.id]);

    //Sorting : default sort by crateTimestamp in desc order
    const [sortKey, setSortKey] = useState<string>("createTimestamp")
    const [sortOrder, setSortOrder] = useState("desc")
    const [headers, setHeaders]: any[] = useState([]);

    useEffect(() => {
        setCust(customersRef.customer);
    }, [customersRef.customer]);

    useEffect(() => {
        setHistory(historyRef.sort((a: any, b: any) => a.createTimestamp < b.createTimestamp ? 1 : -1));

        const headers: any = [
            { key: "reportName", label: "Report History", filter: { type: "text" } },
            { key: "reportType", label: "Type", filter: { type: "select", options: [] } },
            { key: "createTimestamp", label: "Created On", filter: { type: "date" } },
            { key: "reportStatus", label: "Status", filter: { type: "select", options: [] } },
            { key: "action", label: "Action", filter: { type: "clear" } }
        ]

        // Dynamic dropdown options for serch dropdown options        
        historyRef.forEach((temp: any) => {
            headers.forEach((row: any, i: any) => {
                if (row.filter?.type === "select" && row.filter?.options?.indexOf(temp[row.key]) < 0) {
                    headers[i].filter?.options?.push(temp[row.key]);
                }
            })
        })
        setHeaders(headers)
        setSortOrder("desc")
        setSortKey("createTimestamp")
    }, [historyRef, arrowButton]);

    const changeSort = (key: string) => {
        setSortOrder(key === sortKey ? (sortOrder === "ascn" ? "desc" : "ascn") : "ascn")
        setSortKey(key)
    }

    const sortData = useCallback(
        () => {
            let tempHistory = [...history];
            if (!sortKey && !sortOrder) return tempHistory

            const sortedData = tempHistory.filter((obj: any) => {
                let count = 0;
                for (let hi = 0; hi < Object.keys(filterIp).length; hi++) {
                    const key = Object.keys(filterIp)[hi];
                    if ((key.toLowerCase().indexOf("timestamp") >= 0 &&
                        new Date(new Date(obj[key]).setHours(0, 0, 0)) >= new Date((new Date(filterIp[key].startDate)).setHours(0, 0, 0)) &&
                        new Date(new Date(obj[key]).setHours(0, 0, 0)) <= new Date((new Date(filterIp[key].endDate)).setHours(0, 0, 0))) ||
                        (filterIp[key].length > 0 &&
                            (key === "reportName" ?
                                (obj[key] + "|" + obj["templateName"] + JSON.stringify(obj["template"])).toLowerCase().indexOf(filterIp[key].toLowerCase()) >= 0 :
                                obj[key].toLowerCase() === filterIp[key].toLowerCase()
                            )
                        )) {
                        count++;
                    }
                }
                return count === Object.keys(filterIp).filter(k => filterIp[k].length > 0 || filterIp[k].startDate?.length > 0).length;
            }).sort((a: any, b: any) => {
                return a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? 1 : -1;
            });

            if (sortOrder === "desc") {
                return sortedData.reverse();
            }

            return sortedData

        }, [history, sortKey, sortOrder, filterIp]
    );

    const visualize = (hObj: any) => {
        dispatch({ type: "LOADING", data: "Loading report" });
        axios.get(window.ENV.exportDomain.replace("export?", "viewInUI?") + hObj.reportName, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            setData(data[hObj.reportName]);
            setVTemplate({ ...JSON.parse(JSON.stringify(templates[hObj.reportType])), repName: hObj.reportName });
            dispatch({ type: "LOADING", data: "" });
            if (data?.message?.length > 0) {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Report Not Available", message: "Report is not available to visualize." } });
            }
        }).catch((error: AxiosError) => {
            setData({});
            dispatch({ type: "LOADING", data: "" });
            if (error.response?.status === 502) {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Data size is too large to visualize.` } });
            } else {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            }
        });
    }

    const visualizeData = (hObj: any) => {
        axios.post(window.ENV.reportsDomain, { pageIndex: 0, reportName: hObj.reportName }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            if (data.message === "This report cannot be viewed in the browser.") {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Report Not Available", message: "Report is not available to visualize." } });
                return;
            }
            const newConfig: any = {
                token: localStorage.getItem("bearer") + '',
                header: data.headerRow,
                headerOnly: data.headerOnly,
                borderedHeader: true,
                totalLine: data.totalLine,
                totalLinePosition: "BOTTOM",
                data: {
                    reportName: hObj.reportName?.split('.')[0]
                }
            };

            if (hObj?.template?.rows?.length + hObj?.template?.cols?.length > 0) {
                newConfig.pivote = {
                    rows: {
                        count: hObj?.template?.rows.length,
                        color: "info"
                    },
                    cols: {
                        count: hObj?.template?.cols?.length,
                        color: "warning"
                    }
                }
            }

            setConfig((prev: any) => {
                return {
                    ...prev,
                    ...newConfig
                }
            });

            setData({ data: true });
            setVTemplate({ ...JSON.parse(JSON.stringify(templates[hObj.reportType])), repName: hObj.reportName });

            dispatch({ type: "LOADING", data: "" });
        }).catch((error: AxiosError) => {
            setData({});
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
        });
    }

    useEffect(() => {
        setSavedTemplates([...savedAllTemplatesRef]);
    }, [savedAllTemplatesRef]);

    return (
        <React.Fragment>
            <Feedback page="Report History" />
            <FDS.FDSLevel type="two" name="hubPage" mainTitle="Report History" subTitle="Access your previously generated reports from the last 30 days">
                <Container className="fds-pl-container first float">
                    <Row className="fds-pl-column p-3 shadow">
                        {Object.keys(data || {}).length <= 0 ?
                            <Col className="pe-0" sm={2}>
                                <ListGroup className="arrowList">
                                    <ListGroup.Item data-testid="adhocButton" active={arrowButton === buttons[0]} onClick={() => {
                                        historyNav.push('/hub/0')
                                        setArrowButton(buttons[0]); setFilterIp({}); setShowIndex([-1])
                                    }}>ADHOC Reports</ListGroup.Item>
                                    <ListGroup.Item data-testid="schedButton" active={arrowButton === buttons[1]} onClick={() => {
                                        historyNav.push('/hub/0')
                                        setArrowButton(buttons[1]); setFilterIp({}); setShowIndex([-1])
                                    }}>Scheduled Reports</ListGroup.Item>
                                    {/*
                                    <ListGroup.Item data-testid="eeButton" active={arrowButton === buttons[2]} onClick={() => { 
                                        historyNav.push('/hub/0'); 
                                        dispatch({type: "SAVED_HISTORY", data: []});
                                        setArrowButton(buttons[2]); setFilterIp({}); setShowIndex([-1]) }}>Event Reports</ListGroup.Item>
                                */}
                                </ListGroup>
                            </Col> : null
                        }
                        <Col className={`arrowContent ${Object.keys(data || {}).length > 0 ? "p-0" : "ps-0"}`} sm={Object.keys(data || {}).length > 0 ? 12 : 10}>
                            {
                                Object.keys(data || {}).length > 0 ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Button className='backtohistorypage' variant='link' onClick={() => {
                                                    setData({});
                                                }}><i className='fds-glyphs-arrow-left' style={{ top: "1px", position: "relative" }} /> Back to Report History</Button>
                                            </Col>
                                            {data.data ?
                                                <Col className='right'>
                                                    {vTemplate.repName?.toUpperCase()?.indexOf("_CHARTS") < 0 ? <Button variant='link' onClick={() => dispatch(downloadReport(vTemplate.repName))}><i className="fds-glyphs-download" /> Download Report</Button> : null}
                                                </Col>
                                                :
                                                <Col>
                                                    <div className='printWrap right no-print'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="secondary"><i className="fds-glyphs-download"></i> Export All</Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#" onClick={() => FDS.html2Image(document?.querySelector("#resultsWrap"), "PRINT", vTemplate.repName, true)}><i className='fds-glyphs-printer3' /> Print</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => FDS.html2Image(document?.querySelector("#resultsWrap"), "PNG", vTemplate.repName, true)}><i className="fds-glyphs-file-stats" /> Image <small>(.png)</small></Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => FDS.html2Image(document?.querySelector("#resultsWrap"), "PDF", vTemplate.repName, true)}><i className="fds-glyphs-file-pdf" /> PDF <small>(.pdf)</small></Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => dispatch(downloadReport(vTemplate.repName.replace("_CHARTS", "")))}><i className="fds-glyphs-file-spreadsheet" /> Excel Data <small>(.xlsx)</small></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>

                                        <div id="resultsWrap" className='card visualize'>
                                            <div className='pb-3'>
                                                <small>{vTemplate.repName}</small>
                                            </div>
                                            {
                                                ["ARCCIDS"].indexOf(vTemplate.reportName) >= 0 ?
                                                    <ARCCIDataStatus data={data} /> :
                                                    data.data ?
                                                        <>
                                                            {config?.totalLine?.length > 100 ?
                                                                <Alert data-testid="table-main-no-data" className="mt-3" variant="info">
                                                                    <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                                                    <div className="body">Too many columns to render for this search criteria. Download the report
                                                                        <Button className='p-0 ms-2 me-1' variant="link"
                                                                            onClick={() => dispatch(downloadReport(config.data.reportName))} style={{ marginTop: "-3px" }}>
                                                                            <b>here</b>
                                                                        </Button> to view the results.
                                                                    </div>
                                                                </Alert> :
                                                                <div className="resultsWrap" style={{ marginTop: "-16px" }}>
                                                                    <FDSInfiniteTable config={config} onChange={tableChanged} onSort={() => { }} />
                                                                </div>}
                                                        </> :
                                                        <BI data={data} template={vTemplate} showReport={true} />
                                            }
                                        </div>
                                    </> :
                                    null
                            }
                            {
                                Object.keys(data || {}).length <= 0 ?
                                    <div className="card content">
                                        <div className="fds-table-wrap">

                                            <Table data-testid="fds-table" className="fds-table table-striped">
                                                <SearchSortHeader headers={headers} sortKey={sortKey} sortOrder={sortOrder} onSort={changeSort} search={true} onChange={setFilterIp} filterIp={filterIp} />
                                                <tbody data-testid="data-rows">
                                                    {
                                                        sortData().map((hObj: any, i: number) => {
                                                            return (
                                                                <tr className={`${showIndex.indexOf(i) >= 0 || id === hObj.reportName ? "selected" : ""}`} data-testid="list-row" key={i}>
                                                                    <td>
                                                                        <div className="repTitle" onClick={() => {
                                                                            setShowIndex((prev: number[]) => {
                                                                                const ind = prev.indexOf(i);
                                                                                if (ind >= 0) {
                                                                                    return [...prev.filter(item => item !== i)];
                                                                                } else {
                                                                                    return [...prev, i]
                                                                                }
                                                                            });
                                                                            setId(0);
                                                                        }}>
                                                                            <i className={`${showIndex.indexOf(i) >= 0 || id === hObj.reportName ? "fds-glyphs-arrow-down5" : "fds-glyphs-caret-right"} me-3`} />
                                                                            <small className={`${hObj.reportName.indexOf("EXTERNAL_EVENT") > 0 ? "EXT_EVT" : ""}`} style={{ fontWeight: '600', position: "absolute", color: (hObj.reportName?.toUpperCase()?.indexOf("_CHARTS") > 0 ? "brown" : "inherit") }}>{hObj.reportName}</small> <br />
                                                                            {hObj.reportTitle?.length > 0 ?
                                                                                <small style={{ paddingLeft: '2rem', display: 'inline-block', fontWeight: '500', maxWidth: '650px', wordBreak: 'break-all' }}>
                                                                                    {hObj.reportTitle}
                                                                                </small> :
                                                                                null
                                                                            }
                                                                            {
                                                                                hObj.template?.template_name?.length > 0 ?
                                                                                    <div className='pt-2'>
                                                                                        <small className='ps-3 ms-3 pt-3 pb-0'>
                                                                                            <i className='fds-glyphs-copy me-2' />
                                                                                            Template Name: <b>{hObj.template.template_name}</b>
                                                                                        </small>
                                                                                    </div> :
                                                                                    null
                                                                            }
                                                                            {
                                                                                hObj.viewAs?.org ?
                                                                                    <div>
                                                                                        <small className='ps-3 ms-3 pt-3 pb-0'>
                                                                                            <i className='fds-glyphs-eye me-2' />
                                                                                            Viewed As: <b>{hObj.viewAs.orgName}</b>
                                                                                        </small>
                                                                                    </div> :
                                                                                    null
                                                                            }
                                                                        </div>

                                                                        {showIndex.indexOf(i) >= 0 || id === hObj.reportName ? <div className="pt-3 ps-3 ms-3">
                                                                            {hObj.auto_generated || hObj.reportName?.toUpperCase()?.indexOf("EXTERNAL_EVENT") > 0 ?
                                                                                <div>
                                                                                    <div>Airline: {hObj.template?.airline?.toString()}</div>
                                                                                    <div>Email To: {hObj.template?.schedule?.email_config?.emailTo?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>)}</div>
                                                                                    <div>CC: {hObj.template?.schedule?.email_config?.CC?.length > 0 ? hObj.template?.schedule?.email_config?.CC?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>) : FDS.valueOrDash(null)}</div>
                                                                                    <div>BCC: {hObj.template?.schedule?.email_config?.BCC?.length > 0 ? hObj.template?.schedule?.email_config?.BCC?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>) : FDS.valueOrDash(null)}</div>
                                                                                </div> :
                                                                                null
                                                                            }
                                                                            {{ ...templates[window.ENV.props.reportTitles[hObj.reportType.toUpperCase()]?.id] }?.lists?.map((list: any, q: number) => {
                                                                                return list.wraps.map((wrap: any, i: number) => {
                                                                                    return (
                                                                                        <React.Fragment key={i}>
                                                                                            {wrap.fields.map((field: any, j: number) => {
                                                                                                return (
                                                                                                    <React.Fragment key={j}>
                                                                                                        {
                                                                                                            hObj.template[field.name]?.length > 0 || (typeof hObj.template[field.name] === "object" && !Array.isArray(hObj.template[field.name])) ? (
                                                                                                                <span className="fds-tag info ms-0 mb-1">
                                                                                                                    {field.hLabel || field.label}:
                                                                                                                    <b className='ps-2'>
                                                                                                                        {
                                                                                                                            typeof hObj.template[field.name] === "object" && !Array.isArray(hObj.template[field.name]) ?
                                                                                                                                Object.keys(hObj.template[field.name])
                                                                                                                                    .sort((a: any, b: any) => a < b ? 1 : -1)
                                                                                                                                    .map((item: any, k: number) => {
                                                                                                                                        return (<span key={k}>
                                                                                                                                            {hObj.template[field.name][item] ?
                                                                                                                                                <React.Fragment>
                                                                                                                                                    {!field.startDate ? <span style={{ textTransform: 'capitalize' }}>{item}</span> : null}
                                                                                                                                                    {field.startDate && item === "startDate" ?
                                                                                                                                                        hObj.template[field.name]["startDate"][0] + " " + (hObj.template[field.name]["endDate"][0] ? FDS.valueOrDash(null) + " " + hObj.template[field.name]["endDate"][0] : "") +
                                                                                                                                                        (hObj.template[field.name]["startDate"].length > 1 ? " vs " + hObj.template[field.name]["startDate"][1] + " " + (hObj.template[field.name]["endDate"][1] ? FDS.valueOrDash(null) + " " + hObj.template[field.name]["endDate"][1] : "") : "") :
                                                                                                                                                        null}
                                                                                                                                                    {!field.startDate ? `[${hObj.template[field.name][item]?.toString()}]   &nbsp;` : null}
                                                                                                                                                </React.Fragment>
                                                                                                                                                : null
                                                                                                                                            }
                                                                                                                                        </span>)
                                                                                                                                    }) :
                                                                                                                                Array.isArray(hObj.template[field.name]) ?
                                                                                                                                    hObj.template[field.name].map((item: string, i: number) => {
                                                                                                                                        return field.options.filter((opt: any) => opt[field.codeKey] === item)[0]?.[field.labelKey] || item
                                                                                                                                    }).join(", ").toUpperCase() :
                                                                                                                                    (field.type === "select" ?
                                                                                                                                        field.options.filter((opt: any) => opt['key']?.toLowerCase() === hObj.template[field.name].toLowerCase())[0]?.['value'] :
                                                                                                                                        hObj.template[field.name]?.toString())?.toUpperCase()
                                                                                                                        }
                                                                                                                    </b>
                                                                                                                </span>
                                                                                                            ) : null
                                                                                                        }
                                                                                                        {
                                                                                                            field.type === "toggleCheckbox" ?
                                                                                                                field.options.map((item: any, k: number) => {
                                                                                                                    return (
                                                                                                                        <span className="fds-tag info ms-0 mb-1">
                                                                                                                            {item.value}:
                                                                                                                            <b>{hObj.template[item.key] ?
                                                                                                                                (<i className="fds-glyphs-checkbox-checked2 infoTxt" />) :
                                                                                                                                (<i className="fds-glyphs-cancel-square2 dangerTxt" />)
                                                                                                                            }</b>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                }) : null
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            })}
                                                                        </div> : null}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <small className="initial rType" style={{ background: window.ENV.props.reportTitles[hObj.reportType]?.color }}>{(window.ENV.props.reportTitles[hObj.reportType]?.mainTitle || hObj.reportType).match(/\b(\w)/g).join('')}</small>
                                                                            <small>
                                                                                {
                                                                                    (window.ENV.props.reportTitles[hObj.reportType]?.shortTitle || window.ENV.props.reportTitles[hObj.reportType]?.mainTitle || hObj.reportType)?.length > 20 ?
                                                                                        (window.ENV.props.reportTitles[hObj.reportType]?.shortTitle || window.ENV.props.reportTitles[hObj.reportType]?.mainTitle || hObj.reportType)?.split(" ").map((w: any) => <div key={w}>{w}</div>) :
                                                                                        (window.ENV.props.reportTitles[hObj.reportType]?.shortTitle || window.ENV.props.reportTitles[hObj.reportType]?.mainTitle || hObj.reportType)
                                                                                }
                                                                                <div className='childTitle'>{window.ENV.props.reportTitles[hObj.reportType]?.childTitle || null}</div>
                                                                            </small>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        {new Date(hObj.createTimestamp).toLocaleString('en-US', { timeZone: 'Etc/GMT+10' })}
                                                                    </td>
                                                                    <td className={`${statusColor[hObj.reportStatus]}Txt`} style={{ whiteSpace: 'nowrap' }}>
                                                                        <i className={`${statusLogo[hObj.reportStatus]}`} /> {hObj.reportStatus.replace("ZERO", "No Records")}
                                                                    </td>
                                                                    <td className="editMenu">
                                                                        <Dropdown data-testid="list-edit">
                                                                            <Dropdown.Toggle variant="link"><i className="fds-glyphs-more2"></i></Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                {hObj.reportStatus === "Success" ?
                                                                                    <>
                                                                                        {
                                                                                            hObj.template.graphs || ["ARCCIDS"].indexOf(hObj.reportType) >= 0 ?
                                                                                                <Dropdown.Item href="#" onClick={() => visualize(hObj)}><i className="fds-glyphs-file-stats" />Visualize</Dropdown.Item> :
                                                                                                <>{hObj.viewableInBrowser ? <Dropdown.Item href="#" onClick={() => visualizeData(hObj)}><i className="fds-glyphs-file-spreadsheet" />Visualize</Dropdown.Item> : null}</>

                                                                                        }
                                                                                        {hObj.reportName?.toUpperCase()?.indexOf("_CHARTS") < 0 ? <Dropdown.Item href="#" onClick={() => dispatch(downloadReport(hObj.reportName))}><i className="fds-glyphs-download" />Download</Dropdown.Item> : null}
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                                {cust?.data_source?.indexOf("GLOBAL") < 0 && hObj.reportType === "JOURNEY_ESTIMATES" ?
                                                                                    null :
                                                                                    <>
                                                                                        {
                                                                                            hObj.reportType !== "ARCCIDS" ?
                                                                                                <>
                                                                                                    <Dropdown.Item href="#" onClick={() => saveTemplate(hObj)}><i className="fds-glyphs-file-plus" />Clone</Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => reRun(hObj)}><i className="fds-glyphs-rotate-cw3" />Load / Edit</Dropdown.Item>
                                                                                                </> : null
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {hObj.reportStatus === "Processing" ? null : <Dropdown.Item href="#" onClick={() => removeHistory(hObj.reportName)}><i className="fds-glyphs-bin2" />Remove</Dropdown.Item>}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                {sortData().length <= 0 ? <tfoot>
                                                    <tr>
                                                        <td colSpan={100}>
                                                            <Alert data-testid="table-main-no-data" className="m-3 w-100" variant="primary">
                                                                <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                                                <div className="body">There are currently no history data available.</div>
                                                            </Alert>
                                                        </td>
                                                    </tr>
                                                </tfoot> : null}
                                            </Table>
                                        </div>
                                    </div> : null
                            }
                        </Col>
                    </Row>
                </Container>
                <SaveTemplate ip={tIp?.template} type={tIp?.reportType} savedTemplates={savedTemplates} selectedTemplate={selectedTemplate} saveAs={false} show={showTemplateModal} onHide={setShowTemplateModal} />
                <div></div>
            </FDS.FDSLevel>
        </React.Fragment>
    )
}