import React, { useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Button, Form, Dropdown, ListGroup, ButtonGroup } from 'react-bootstrap';
import { DynamicForm } from './DynamicForm';
import { templates, dependencies, Const, getTreeNodes, capitalize, random, getTreeChildren } from '../utils';
import * as FDS from "@arctravel/react-fds/lib";
import { getAllSavedTemplatesAction, getSavedTemplatesAction } from '../../store/actions';
import { useSelector, useDispatch } from 'react-redux';

import PivotTableUI from "react-pivottable/PivotTableUI";
import SaveTemplate from '../modals/SaveTemplate';
import Schedular from '../modals/Schedular';

import { iiPivotHead, iiPivotBody } from '../utils/SampleData/InteractiveInsights'

import 'react-pivottable/pivottable.css';
import './ReportFilters.scss';
import { getOts } from '../../okta/ls';

declare const window: any;

const requiredGroupTxt = "At least one of this type's values is required.";

interface FilterProps {
    params: any,
    onReport: any,
    onLoadRun: boolean,
    customer: any,
}

export const NewReportFilters = (props: FilterProps) => {
    const [templateAsTxt, setTemplateAsTxt] = useState(JSON.stringify(templates[props.params]));
    const custom_groups_key_txt = "groupTemplateMappings";
    const [tabIndex, setTabIndex] = useState<number>(0);
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const savedTemplatesRef = useSelector((state: any) => state.savedTemplatesRef);
    const templatesLoadedRef = useSelector((state: any) => state.templatesLoadedRef);
    const savedAllTemplatesRef = useSelector((state: any) => state.savedAllTemplatesRef);
    const [savedAllTemplates, setSavedAllTemplates]: any = useState([...savedAllTemplatesRef]);
    const rerunTemplateRef: any = useSelector((state: any) => state.rerunTemplateRef);
    const customersRef: any = useSelector((state: any) => state.customersRef);

    const dispatch = useDispatch();

    const [savedTemplates, setSavedTemplates]: any = useState([...savedTemplatesRef]);
    const [selectedTemplate, setSelectedTemplate]: any = useState({ template_name: "" });
    const [viewAs, setViewAs] = useState(customersRef.viewAs);
    const changes: any = { rows: [], cols: [] };
    const filterWrap = useRef(templates[props.params].filtersOnClick);

    //dsr RULE templates
    const handleDefault = useCallback(() => {
        let templateH = JSON.parse(templateAsTxt);

        if (props.customer?.entity === "THIRDPARTY-FINANCIAL") {
            // Default Airport Tab
            switch (templateH.reportName.toUpperCase()) {
                case "JOURNEY":
                case "AGENCY_PERFORMANCE":
                case "AIRLINE_PERFORMANCE":
                case "BENCHMARK":
                case "ARCCI":
                case "ROUTING":
                case "JOURNEY_ESTIMATES":
                    templateH.lists[0].wraps[2].fields[1].show = true;
                    templateH.lists[0].wraps[2].fields[0].show = false;
                    templateH.lists[0].wraps[3].fields[1].show = true;
                    templateH.lists[0].wraps[3].fields[0].show = false;
                    break;
                case "INTERACTIVE":
                    // General tab
                    templateH.lists[0].wraps[3].fields[1].show = true;
                    templateH.lists[0].wraps[3].fields[0].show = false;
                    // Segment tab
                    templateH.lists[1].wraps[0].fields[1].show = true;
                    templateH.lists[1].wraps[0].fields[0].show = false;
                    templateH.lists[1].wraps[1].fields[1].show = true;
                    templateH.lists[1].wraps[1].fields[0].show = false;
                    // Trip tab
                    templateH.lists[2].wraps[0].fields[1].show = true;
                    templateH.lists[2].wraps[0].fields[0].show = false;
                    templateH.lists[2].wraps[1].fields[1].show = true;
                    templateH.lists[2].wraps[1].fields[0].show = false;
                    break;
                default:
                    break;
            }
        }

        // Default enable checkbox for agency user
        if (props.customer?.entity === "AGENCY") {
            switch (templateH.reportName.toUpperCase()) {
                case "JOURNEY":
                case "JOURNEY_ESTIMATES":
                case "ROUTING":
                    const index = templateH.lists[0].wraps[21].fields[0].value.findIndex((obj: any) => obj.hasOwnProperty("show_agencies"));
                    if (index > 0) {
                        templateH.lists[0].wraps[21].fields[0].value[index].show_agencies = true
                        templateH.lists[0].wraps[20].fields[0].value = "airline"
                    }
                    break;
                default:
                    break;
            }
        }        

        if ("peerGroups" in props.customer && templateH.reportName === "BENCHMARK") {
            const index = templateH.lists[0].wraps.findIndex((i: any) => i.name === "peer_group")
            templateH.lists[0].wraps[index].fields[0].options = Object.keys(props.customer?.peerGroups).map((item) => ({ code: item }))
            templateH.lists[0].wraps[index].fields[0].value = [Object.keys(props.customer?.peerGroups).map((item) => ({ code: item }))[0]]
        }

        if ("peerGroups" in props.customer && templateH.reportName === "BENCHMARK") {
            const index = templateH.lists[0].wraps.findIndex((i: any) => i.name === "peer_group")
            templateH.lists[0].wraps[index].fields[0].options = Object.keys(props.customer?.peerGroups).map((item) => ({ code: item }))
            templateH.lists[0].wraps[index].fields[0].value = [Object.keys(props.customer?.peerGroups).map((item) => ({ code: item }))[0]]
        }
        return { ...templateH }
    }, [templateAsTxt, props.customer])
    const [templateIp, setTemplateIp] = useState(handleDefault());
    const [templateJSON, setTemplateJSON] = useState(handleDefault());
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [showSchedularModal, setShowSchedularModal] = useState(false);
    const [saveAs, setSaveAs] = useState(false);
    const [ip, setIp]: any = useState({});
    const [Refip, setRefIp] = useState({});

    const [res, setRes] = useState({
        pivotTable: {
            data: [...iiPivotHead, ...iiPivotBody],
            metrics: [],
            rows: [],
            cols: [],
            vals: []
        },
    });

    const refineRegions = (prev: any) => {
        const regions = ['o', 'd'];
        for (let p = 0; p < regions.length; p++) {
            for (let k = 0; k < prev[`${regions[p]}_iata_subzne1_cd`]?.length; k++) {
                if (["TC1", "TC2", "TC3", "XU"].indexOf(prev[`${regions[p]}_iata_subzne1_cd`][k]) >= 0) {
                    if (!prev[`${regions[p]}_iata_zne_cd`]) {
                        prev[`${regions[p]}_iata_zne_cd`] = [];
                    }
                    prev[`${regions[p]}_iata_zne_cd`].push(prev[`${regions[p]}_iata_subzne1_cd`][k]);
                    prev[`${regions[p]}_iata_subzne1_cd`].splice(k, 1);
                    k--;
                }
            }
        }

        return prev;
    }

    const refineToMQReportPeriod = (prev: any) => {
        const dtTypes = [
            "quarter_report_period",
            "compare_m_report_period",
            "compare_q_report_period"
        ];

        dtTypes.forEach((type: string) => {
            if (prev[type]) {
                prev["month_report_period"] = JSON.parse(JSON.stringify(prev[type]));
                delete prev[type];
            }
        })

        if (prev["summary_report_period"]) {
            const dt = prev["summary_report_period"].startDate[0];
            const m = dt.substring(0, dt.indexOf("/"))
            const y = dt.substring(dt.indexOf("/") + 1, dt.length) - 1;

            prev["month_report_period"] = {
                startDate: [m + "/" + y],
                endDate: [dt]
            }
            delete prev["summary_report_period"];
        }

        if (prev["compare_y_report_period"]) {
            prev["month_report_period"] = {
                startDate: ["1/" + prev["compare_y_report_period"].startDate[0], "1/" + prev["compare_y_report_period"].startDate[1]],
                endDate: ["12/" + prev["compare_y_report_period"].startDate[0], "12/" + prev["compare_y_report_period"].startDate[1]]
            }
            delete prev["compare_y_report_period"];
        }

        return prev;
    }

    const refineMYToMDY = (prev: any, type: string) => {
        prev[type].startDate = prev[type].startDate.map((d: string) => {
            d += "";
            const m = parseInt(d.substring(0, d.indexOf("/")));
            const y = parseInt(d.substring(d.indexOf("/") + 1, d.length));

            return d.length === 4 ? "1/1/" + d : (d.length < 8 ? m + "/1/" + y : d);
        })
        prev[type].endDate = prev[type].endDate.map((d: string) => {
            d += "";
            const m = parseInt(d.substring(0, d.indexOf("/")));
            const y = parseInt(d.substring(d.indexOf("/") + 1, d.length));

            return d.length === 4 ? "12/31/" + d : (d.length < 8 ? m + "/" + new Date(y, m, 0).getDate() + "/" + y : d);
        })
        return prev;
    }

    const refineTOND = (prev: any) => {
        // Regions
        prev = refineRegions(prev);

        //show_monthly_breakdown
        prev[`show_${prev['date_type'] === 'purchase_date' ? 'travel' : 'purchase'}_month`] = prev['show_monthly_breakdown'];
        delete prev['show_monthly_breakdown'];

        //To report_period: MM/YYYY
        prev = refineToMQReportPeriod(prev);

        return prev;
    }

    const refineINTERACTIVE = (prev: any) => {
        // Regions
        prev = refineRegions(prev);

        // Rename all_**_report_period to purchase_date[_pp_] or travel_date[_tp_]
        if (prev["purchase_all_report_period"]) {
            prev["purchase_date"] = prev["purchase_all_report_period"];
            delete prev["purchase_all_report_period"];
        }

        if (prev["travel_all_report_period"]) {
            prev["travel_date"] = prev["travel_all_report_period"];
            delete prev["travel_all_report_period"];
        }

        // Convert MM/YYYY to MM/DD/YYYY
        // Rename **_**_report_period to purchase_date[_pp_] or travel_date[_tp_]
        const dtTypes = [
            "purchase_day_report_period",
            "travel_day_report_period",

            "purchase_month_report_period",
            "travel_month_report_period",

            "purchase_quarter_report_period",
            "travel_quarter_report_period",

            "purchase_year_report_period",
            "travel_year_report_period",

            "purchase_compare_m_report_period",
            "travel_compare_m_report_period",

            "purchase_compare_q_report_period",
            "travel_compare_q_report_period"
        ];
        dtTypes.forEach((type: string) => {
            if (prev[type]) {
                prev = refineMYToMDY(prev, type);
                prev[type.indexOf("purchase_") >= 0 ? "purchase_date" : "travel_date"] = JSON.parse(JSON.stringify(prev[type]));
                delete prev[type];
            }
        })

        // Rename **_summary_report_period to purchase_date[purchase_] or travel_date[travel_]
        const sumDtTypes = ["purchase_summary_report_period", "travel_summary_report_period"];
        sumDtTypes.forEach((type: string) => {
            if (prev[type]) {
                const dt = prev[type].startDate[0];
                const m = dt.substring(0, dt.indexOf("/"))
                const y = dt.substring(dt.indexOf("/") + 1, dt.length);

                prev[type.indexOf("purchase_") === 0 ? "purchase_date" : "travel_date"] = {
                    startDate: [m + "/1/" + (y - 1)],
                    endDate: [m + "/" + new Date(y, m, 0).getDate() + "/" + y]
                }
                delete prev[type];
            }
        })


        // Rename y_**_comp_period to purchase_date[_pp_] or travel_date[_tp_]
        const yDtTypes = ["purchase_compare_y_report_period", "travel_compare_y_report_period"];
        yDtTypes.forEach((type: string) => {
            if (prev[type]) {
                prev[type.indexOf("purchase_") >= 0 ? "purchase_date" : "travel_date"] = {
                    startDate: ["1/1/" + prev[type].startDate[0], "1/1/" + prev[type].startDate[1]],
                    endDate: ["12/31/" + prev[type].startDate[0], "12/31/" + prev[type].startDate[1]]
                }
                delete prev[type];
            }
        })

        if (prev.cols && prev.cols.length > 0) {
            prev.cols = prev.cols?.filter((val: string) => val?.toLowerCase() !== "metrics");
        }

        return prev;
    }

    const refineROUTING = (prev: any) => {
        // Regions
        prev = refineRegions(prev);

        //show_monthly_breakdown
        prev[`show_${prev['date_type'] === 'purchase_date' ? 'travel' : 'purchase'}_month`] = prev['show_monthly_breakdown'];
        delete prev['show_monthly_breakdown'];

        //To report_period: MM/YYYY
        prev = refineToMQReportPeriod(prev);

        // Convert MM/YYYY to MM/DD/YYYY
        const dtTypes = [
            "month_report_period"
        ];
        dtTypes.forEach((type: string) => {
            if (prev[type]) {
                prev = refineMYToMDY(prev, type);
                prev[prev['date_type'][0]] = JSON.parse(JSON.stringify(prev[type]));
            }
        })

        return prev;
    }

    const refineIp = (executionType: string) => {
        //console.log("refineIp-------------------------------called----------")
        if (executionType === "T") { // Only Template
            setSaveAs(true)
            setShowTemplateModal(true);
        } else { // Only Report Generation
            let prev = JSON.parse(JSON.stringify(ip));

            //console.log("******************************", prev)

            const qMap: any = {
                Q1: "1",
                Q2: "4",
                Q3: "7",
                Q4: "10"
            }
            Object.keys(prev).forEach((k: string) => {
                // Strings to Array
                if (typeof prev[k] === 'string') {
                    prev[k] = prev[k].length > 0 ? [prev[k]] : [];
                }

                // Q Date to Simple Date
                prev[k]?.startDate?.forEach((item: any, ii: number) => {
                    if (prev[k].startDate && (prev[k]?.startDate[ii] + '').indexOf("Q") >= 0) {
                        const q1: string = (prev[k]?.startDate[ii] + '').substring(0, (prev[k].startDate[ii] + '')?.indexOf("/"));
                        prev[k].startDate[ii] = (prev[k]?.startDate[ii] + '').replace(q1, qMap[q1]);

                        const q2: string = (prev[k]?.endDate[ii] + '').substring(0, (prev[k].endDate[ii] + '')?.indexOf("/"));
                        prev[k].endDate[ii] = (prev[k]?.endDate[ii] + '').replace(q2, (parseInt(qMap[q2]) + 2) + '');
                    }
                })
            })

            switch (props.params.toUpperCase()) {
                case window.ENV.props.reportTitles.JOURNEY.id:
                    prev = refineTOND(prev);
                    break;
                case window.ENV.props.reportTitles.INTERACTIVE.id:
                    prev = refineINTERACTIVE(prev);
                    break;
                case window.ENV.props.reportTitles.ROUTING.id:
                    prev = refineROUTING(prev);
                    break;
                default:
                    break;
            }

            // Dependencies
            const dt = dependencies[props.params] || [];
            for (let x = 0; x < dt.length; x++) {
                const keySide = handleDefault().lists[dt[x].listId]?.wraps[dt[x].keyFrom[0]]?.fields[dt[x].keyFrom[1]]?.name;
                const valSide = handleDefault().lists[dt[x].listId]?.wraps[dt[x].valFrom[0]]?.fields[dt[x].valFrom[1]]?.name;
                const keyField = handleDefault().lists[dt[x].listId]?.wraps[dt[x].keyFrom[0]]?.fields[dt[x].keyFrom[1]];
                if (keySide && keyField.options.length) {
                    if (prev[keySide] && Array.isArray(prev[keySide])) {
                        prev[keySide].forEach((a: string) => {
                            const A = keyField.options?.filter((opt: any) => opt[keyField.type === "select" ? "key" : keyField["codeKey"]] === a)[0][keyField.type === "select" ? "key" : keyField["labelKey"]];
                            prev[a] = prev[valSide] ? JSON.parse(JSON.stringify(prev[valSide])) : "";
                            if (prev[custom_groups_key_txt]?.[valSide]) {
                                prev[(props.params.toUpperCase() === "INTERACTIVE" ? A : a) + "_map"] = JSON.parse(JSON.stringify(prev[custom_groups_key_txt][valSide]));
                            }
                        })
                    } else {
                        prev[prev[keySide]] = prev[valSide] ? JSON.parse(JSON.stringify(prev[valSide])) : "";
                        if (prev[custom_groups_key_txt]?.[valSide]) {
                            prev[prev[keySide] + "_map"] = JSON.parse(JSON.stringify(prev[custom_groups_key_txt][valSide]));
                        }
                    }
                }
                delete prev[valSide];
                delete prev[keySide];
                if (prev[custom_groups_key_txt]?.[valSide]) {
                    delete prev[custom_groups_key_txt][valSide];
                }
            }
            // END: Dependencies

            // Group Name in Output
            Object.keys(prev).forEach((k: any) => {
                if (k.indexOf("_map") > 0) {
                    prev[custom_groups_key_txt] = prev[custom_groups_key_txt] || {};
                    prev[custom_groups_key_txt][k.replace("_map", "")] = prev[k];
                    delete prev[k];
                }
            })
            // END

            const reportPreference: any = {};
            templateIp.lists?.forEach((list: any) => {
                list.wraps.forEach((wrap: any) => {
                    wrap.fields.forEach((field: any) => {
                        if (wrap.excludable) {
                            reportPreference[field.name + "_exclude"] = "Exclude " + (field.rLabel ? field.rLabel : field.label)
                        }
                        if (wrap.masking) {
                            reportPreference[field.name + "_unmask"] = "Mask " + (field.rLabel ? field.rLabel : field.label)
                        }

                        if (wrap.type === 'option' && wrap.condition === 'OR' && !field.show) {
                            // Continue
                        } else {
                            if (field.type === 'toggleCheckbox') {
                                field.options.forEach((op: any) => {
                                    reportPreference[op.key] = op.rLabel ? op.rLabel : op.value;
                                })
                            } else {
                                reportPreference[field.name] = field.rLabel ? field.rLabel : field.label
                            }
                        }
                    })
                });
            });

            const chartsLists = JSON.parse(templateAsTxt).chartsLists || [];
            const chartTemplate: any = {};
            for (let i = 0; i < chartsLists.length; i++) {
                const wraps = chartsLists[i].wraps;
                for (let j = 0; j < wraps.length; j++) {
                    const chatUtil = (fields: any) => {
                        for (let k = 0; k < fields?.length; k++) {
                            const field: any = fields[k];
                            const metrics: any = (field.serviceKeys || field.keys).values || [(field.serviceKeys || field.keys).value];
                            const rows: any = (field.serviceKeys || field.keys).labels || [(field.serviceKeys || field.keys).label];

                            if (!field?.showFor || (field?.showFor && props?.customer?.data_content.indexOf(field?.showFor?.subscription) >= 0)) {
                                if (field.hasOwnProperty("aliasName")) {
                                    chartTemplate[field.name] = {
                                        ...chartTemplate[field.name],
                                        metrics: (field?.serviceKeys || field?.keys)?.values ?
                                            [...chartTemplate[field.name]?.metrics, ...(field?.serviceKeys || field?.keys)?.values] :
                                            [...chartTemplate[field.name]?.metrics, (field?.serviceKeys || field?.keys)?.value]
                                    }
                                } else if (metrics.length > 0 && metrics.indexOf(undefined) < 0 && rows.length > 0 && rows.indexOf(undefined) < 0) {
                                    chartTemplate[field.name] = {
                                        metrics: metrics,
                                        rows: rows
                                    };
                                }
                            }
                        }
                    }

                    if (wraps[j].tabs) {
                        for (let l = 0; l < wraps[j].tabs.length; l++) {
                            chatUtil(wraps[j].tabs[l].fields)
                        }
                    } else {
                        chatUtil(wraps[j].fields)
                    }
                }
            }

            //delete ip.ndc;
            const r = JSON.parse(JSON.stringify({
                template: { ...ip, template_name: selectedTemplate.template_name },
                //reportPreference: { ...reportPreference },
                reportType: props.params.toUpperCase(),
                executionType: executionType
            }));

            if (Object.keys(chartTemplate).length > 0) {
                r.template.graphs = chartTemplate;
                setIp((prev: any) => {
                    return { ...prev, graphs: chartTemplate }
                })
            }

            if (viewAs.org) {
                r.viewAs = {
                    "entity": viewAs?.entity?.toUpperCase(),
                    "org": viewAs?.org,
                    "orgName": viewAs?.orgName,
                    "role": viewAs?.role?.toUpperCase()
                };
            }

            if (executionType === "D") {
                r['action'] = "download";
            }

            setRefIp({ ...r, report: { ...prev } });

            if (executionType === "A") {
                dispatch({ type: "REPORT_IP", data: r });

                setTimeout(() => {
                    window?.scrollTo(0, 0);
                    props.onReport();
                }, 10);
            } if (executionType === "D") {
                dispatch({ type: "REPORT_IP", data: r });

                setTimeout(() => {
                    window?.scrollTo(0, 0);
                }, 10);
            }
        }
    }

    const prepareIP = (executionType: string) => {
        if (["I", "T", "S"].indexOf(executionType) < 0) {
            dispatch({ type: "N_STATUS", data: { type: "INFO", title: "Notification", message: "", body: {}, statusCode: 200 } });
        }

        let requiredSelected = [0, 0];
        let requiredGroups: any = {};
        let allRegionCount = 0;
        let isGroupsCleared = false;
        const requiredGroupNames: string[] = [];
        const requiredNames: string[] = [];

        for (let ti = 0; ti < templateIp.lists.length; ti++) {
            const list = templateIp.lists[ti];
            if (!list?.showFor || props.customer.data_content.indexOf(list?.showFor.subscription) >= 0) {
                for (let wi = 0; wi < list.wraps.length; wi++) {
                    const wrap = list.wraps[wi];
                    let isWrapDone = false, isWrapRun = false;
                    for (let fi = 0; fi < wrap.fields.length; fi++) {
                        const field = wrap.fields[fi];
                        let isRun = false;
                        let isGroupRun = false;

                        const prev = ip;

                        if (!isGroupsCleared) {
                            prev[custom_groups_key_txt] = {};
                            isGroupsCleared = true;
                        }
                        // Group Name in Output
                        if (field.outputLabel && field.value && typeof field.value === "object" && field.type !== "toggleCheckbox" && field.value.length > 0 && field.value.filter((v: any) => v.top).length > 0) {
                            prev[field.name + "_map"] = {};
                            for (let vi = 0; vi < field.value.length; vi++) {
                                const v = field.value[vi];
                                if (v.top) {
                                    const ac = v[field.codeKey];
                                    const gc = v[field.labelKey];

                                    if (prev[field.name + "_map"][ac] === undefined) {
                                        prev[field.name + "_map"][ac] = [];
                                    }
                                    if (gc && gc.length > 0) {
                                        prev[field.name + "_map"][ac].push(gc);
                                    }
                                }
                            };
                        }

                        const prevKeys = Object.keys(prev);
                        for (let ki = 0; ki < prevKeys.length; ki++) {
                            const k = prevKeys[ki];
                            if (k.indexOf("_map") > 0) {
                                prev[custom_groups_key_txt] = prev[custom_groups_key_txt] || {};
                                prev[custom_groups_key_txt][k.replace("_map", "")] = prev[k];
                                delete prev[k];
                            }
                        }
                        // END
                        wrap.exclude ? prev[field.name + "_exclude"] = wrap.exclude : delete prev[field.name + "_exclude"]
                        wrap.unmask ? prev[field.name + "_unmask"] = wrap.unmask : delete prev[field.name + "_unmask"]

                        if (wrap?.required && (wrap?.hideFor?.entity || []).indexOf(props.customer?.entity) < 0 && !isRun) {
                            if (fi === 0) {
                                requiredSelected[0]++;
                            }
                            if (field.value.length > 0 && !isWrapDone) {
                                requiredSelected[1]++;
                                isWrapDone = true;
                                isRun = true;
                            }
                            isWrapRun = true;
                        } else if (field?.required && (field.disableFor?.entity || []).indexOf(props.customer?.entity) < 0 && (!wrap?.hideFor || wrap?.hideFor?.entity.indexOf(props.customer?.entity) < 0)) {
                            requiredSelected[0]++;
                            if (field.value.length > 0) {
                                requiredSelected[1]++;
                            } else if (requiredNames.indexOf(wrap?.name) < 0) {
                                requiredNames.push(wrap?.name);
                            }
                        }

                        if (wrap?.requiredGroup && (wrap?.hideFor?.entity?.indexOf(props.customer?.entity) < 0 || field.disableFor?.entity.indexOf(props.customer?.entity) < 0) && !isGroupRun) {
                            if (requiredGroupNames.indexOf(wrap?.name) < 0) {
                                requiredGroupNames.push(wrap?.name);
                            }
                            if (requiredGroups[wrap?.requiredGroup] === undefined) {
                                requiredGroups[wrap?.requiredGroup] = 0;
                            }
                            if (field.value.length > 0) {
                                requiredGroups[wrap?.requiredGroup]++;
                                isGroupRun = true;
                            }
                        }

                        switch (field.type) {
                            case "date":
                            case "monthYearPicker":
                            case "quarterPicker":
                            case "yearPicker":
                                if (!field.hasOwnProperty("show") || (field.hasOwnProperty("show") && field.show)) {
                                    const startDate: string[] = []
                                    const endDate: string[] = []
                                    field?.startDate.forEach((date: any) => startDate.push(date.toString()));
                                    field?.endDate.forEach((date: any) => endDate.push(date.toString()));
                                    prev[field.name] = { startDate: startDate, endDate: endDate };
                                } else {
                                    delete prev[field.name];
                                }
                                break;
                            case "toggleCheckbox":
                                field.value.forEach((valObj: any) => {
                                    const valKey = Object.keys(valObj)[0];
                                    prev[valKey] = valObj[valKey];
                                })
                                break;
                            case "typeahead":
                                // For ARCCIDS we need Name of the corporation as well for reporting purpose.
                                if (templateIp.reportName.toUpperCase() === "ARCCIDS" && (field.name === 'corporation' || field.name === 'tmcgroup' || field.name === 'tmc')) {
                                    prev[field.name] = [...field.value].map((valObj: any) => valObj[field.labelKey]?.toUpperCase() + "(" + valObj[field.codeKey]?.toUpperCase() + ")");
                                }
                                else {
                                    prev[field.name] = [...field.value].map((valObj: any) => valObj[field.codeKey])
                                }
                                break;
                            case "tree":
                                const TCs = prev[field.name]?.filter((val: string) => val.indexOf("TC1:") >= 0 || val.indexOf("TC2:") >= 0 || val.indexOf("TC3:") >= 0) || [];
                                if (TCs.length >= 3 && field.restrictTCs) {
                                    ++allRegionCount;
                                }
                                prev[field.name] = (
                                    templateIp.reportName === "INTERACTIVE" && field.type === "tree" && field.treeChildrenCheck ?
                                        filterTreeRules(field, "codeKey") :
                                        [...field.value].map((valObj: any) =>
                                            valObj[field.codeKey] +
                                            (valObj["region_type"] === "STATE_CD" ? ("#" + valObj["country code"]) : "")
                                        )
                                );
                                break;
                            case "hidden":
                                prev[field.name] = field.value;
                                break;
                            default:
                                if (("show" in field && field.show) || (!("show" in field))) {
                                    prev[field.name] = field.value;
                                }
                        }

                        if (wrap.type === "option" && wrap.condition === "OR" && !field.show) {
                            delete prev[field.name];
                            delete prev[custom_groups_key_txt][field.name];
                        }

                        setIp(prev);
                    };

                    if (!isWrapDone && isWrapRun) {
                        if (requiredNames.indexOf(wrap?.name) < 0) {
                            requiredNames.push(wrap?.name);
                        }
                    }
                }
            }
        }

        if (executionType !== "I") {
            let errMsg;

            if (props.params.toUpperCase() === window.ENV.props.reportTitles.INTERACTIVE.id && ip.ticket?.length <= 0 && ip.pnr?.length <= 0 &&
                ip.purchase_all_report_period === "all" && ip.purchase_all_report_period === ip.travel_all_report_period) {
                errMsg = { type: "WARNING", title: "Date Fields", message: `Please revise your query to include 'ALL' for either "Purchase Date" or "Travel Date" but not for both` };
                setTabIndex(0);
            } else if (requiredSelected[0] !== requiredSelected[1]) {
                errMsg = { type: "WARNING", title: "Missing Field", message: `Please revise your query to include all required(*) fields. [${requiredNames.join(", ")}]` };
                setTabIndex(0);
            } else if (Object.keys(requiredGroups).filter(k => requiredGroups[k] === 0).length > 0) {
                errMsg = { type: "WARNING", title: "Missing Field", message: `Please revise your query to include at least one of the field(s). [${requiredGroupNames.join(", ")}]` };
                setTabIndex(0);
            } else if (allRegionCount > 1) {
                errMsg = { type: "WARNING", title: "Region Fields", message: `Please revise your query to include all 3 regions(TC1, TC2, TC3) for either "Origin" or "Destination" but not for both` };
                setTabIndex(0);
            } else if (ip.data_metrics?.indexOf("seg_pax") >= 0 && ip.data_metrics?.indexOf("trip_pax") >= 0) {
                errMsg = { type: "WARNING", title: "Data Metrics", message: `Please select either "Segment Passengers" or "Trip Passengers" but not both from Data Metrics.` };
                setTabIndex(templateIp.lists.length - 3);
            } else if (ip.cols?.length > 5) {
                errMsg = { type: "WARNING", title: "Column Fields", message: `Please select no more than 5 column fields.` };
                setTabIndex(templateIp.lists.length - 1);
            }

            if (errMsg) {
                window.scrollTo({
                    top: 350,
                    left: 0,
                    behavior: 'smooth'
                });
                dispatch({ type: "LOADING", data: "" });
                dispatch({ type: "STATUS", data: errMsg });
                return;
            }
        }

        let t: any = null;
        executionType === "S" && setShowSchedularModal(true)
        executionType === "T" && setSaveAs(true)
        t = setTimeout(() => {
            clearTimeout(t);
            t = null;
            refineIp(executionType);
        }, 100)
    }

    // Handler for loading saved, select, default template
    const setSelectedSavedTemplate = useCallback((data: any) => {
        console.log("Template data", data)

        setSelectedTemplate({ ...data });

        let prev = handleDefault();

        for (let q = 0; q < prev.lists.length; q++) {
            const list = prev.lists[q];
            if (!list?.showFor?.subscription || props.customer.data_content.indexOf(list?.showFor?.subscription) >= 0) {
                for (let i = 0; i < list.wraps.length; i++) {
                    const wrap = list.wraps[i];
                    const fields = wrap.fields;
                    if (!wrap?.showFor || props.customer.data_content.indexOf(wrap?.showFor?.subscription) >= 0) {
                        for (let j = 0; j < fields.length; j++) {
                            const field = fields[j];
                            const mapList: any = {};
                            if (!field?.showFor || props.customer.data_content.indexOf(field?.showFor?.subscription) >= 0) {
                                if (data[field.name + "_exclude"]) {
                                    wrap.exclude = data[field.name + "_exclude"];
                                }
                                if (data[field.name + "_unmask"]) {
                                    wrap.unmask = data[field.name + "_unmask"];
                                }
                                if (field.url) {
                                    let ls: any[] = wrap.checks && props.customer.data_content.indexOf(wrap.checks) >= 0 ? [...referenceDataRef[field.url].filter((opt: any) => opt[field.codeKey] === props.customer.code), { acct: "all", code: "all_airlines", description: "All Other Airlines", name: "All Other Airlines" }] : referenceDataRef[field.url];

                                    if (wrap.checks && data[field.name]) {
                                        data[field.name] = data[field.name].filter((val: string) => ls.some((obj: any) => obj[field.codeKey] === val))
                                    }

                                    // For Tree Only - Children
                                    for (let i = 0; i < ls?.length; i++) {
                                        if (ls[i].children && ls[i].children.length > 0) {
                                            const c = [...ls[i].children];
                                            ls[i].children.length = 0;
                                            ls = [...ls, ...c];
                                        }
                                    }

                                    // Convert to map
                                    for (let i = 0; i < ls?.length; i++) {
                                        if (!ls[i][field.codeKey]) {
                                            continue;
                                        }
                                        mapList[ls[i][field.codeKey].toUpperCase()] = ls[i];
                                    }
                                } else if (field.options) {
                                    let ls: any[] = field.options;

                                    if (field.type === "tree") {
                                        ls = getTreeNodes(field, field.options)
                                    }
                                    // Convert to map from options
                                    for (let i = 0; i < ls?.length; i++) {
                                        if (!ls[i][field.codeKey]) {
                                            continue;
                                        }
                                        mapList[ls[i][field.codeKey].toUpperCase()] = ls[i];
                                    }
                                }

                                if (field.type === "tree" && field.treeChildrenCheck) {
                                    data[field.name] = data[field.name]?.filter((v: any) => {
                                        return (field.noTop ? field.options.filter((op: any) => !op.top) : getTreeChildren(props.customer, field.options))
                                            .filter((o: any) => o.key?.split(",").indexOf(v) >= 0).length > 0;
                                    });
                                }

                                switch (field.type) {
                                    case "date":
                                        if (data[field.name]?.startDate[0]?.indexOf("Q") < 0 && data[field.name]?.startDate[0]?.length > 8) {
                                            field.startDate = data[field.name]?.startDate;
                                            field.endDate = data[field.name]?.endDate;
                                            field.show = true;
                                        } else {
                                            field.show = false;
                                        }
                                        if (!data[field.name] && JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].show === true))) {
                                            if (Object.keys(data).length <= 0) {
                                                field.show = true;
                                            }
                                            field.startDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].startDate));
                                            field.endDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].endDate));
                                        }
                                        break;
                                    case "monthYearPicker":
                                        if (data[field.name]?.startDate[0]?.indexOf("Q") < 0 && data[field.name]?.startDate[0]?.length < 8) {
                                            field.startDate = data[field.name]?.startDate;
                                            field.endDate = data[field.name]?.endDate;
                                            field.show = true;
                                        } else {
                                            field.show = false;
                                        }
                                        if (!data[field.name] && j === 0) {
                                            if (Object.keys(data).length <= 0) {
                                                field.show = true;
                                            }
                                            //console.log(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j]))
                                            field.startDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].startDate));
                                            field.endDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].endDate));
                                        }
                                        break;
                                    case "quarterPicker":
                                        if (data[field.name]?.startDate[0]?.indexOf("Q") >= 0) {
                                            field.startDate = data[field.name]?.startDate;
                                            field.endDate = data[field.name]?.endDate;
                                            field.show = true;
                                        } else {
                                            field.show = false;
                                        }
                                        if (!data[field.name] && j === 0) {
                                            if (Object.keys(data).length <= 0) {
                                                field.show = true;
                                            }
                                            field.startDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].startDate));
                                            field.endDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].endDate));
                                        }
                                        break;
                                    case "yearPicker":
                                        if (data[field.name]?.startDate[0]?.toString().indexOf("Q") < 0 && data[field.name]?.startDate[0]?.toString().length < 5) {
                                            field.startDate = data[field.name]?.startDate;
                                            field.endDate = data[field.name]?.endDate;
                                            if (field.compare && data[field.name]?.endDate.length > 0) {
                                                field.startDate = [...data[field.name]?.startDate, ...data[field.name]?.endDate];
                                                field.endDate = [];
                                            }
                                            field.show = true;
                                        } else {
                                            field.show = false;
                                        }
                                        if (!data[field.name] && j === 0) {
                                            if (Object.keys(data).length <= 0) {
                                                field.show = true;
                                            }
                                            field.startDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].startDate));
                                            field.endDate = JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].endDate));
                                        }
                                        break;
                                    case "toggleCheckbox":
                                        let count = 0;
                                        field.value.length = 0;
                                        for (let o = 0; o < field.options.length; o++) {
                                            const opt: any = field.options[o];
                                            if (data[opt.key] !== undefined && data[opt.key] !== null) { //Must be undefined, null check since data is boolean
                                                const ov: any = {};
                                                const k = opt.key;
                                                ov[k] = data[opt.key];
                                                field.value.push(ov);

                                                if (ov[k] === true) {
                                                    count++;
                                                }
                                            } else {
                                                const v = handleDefault().lists[q].wraps[i].fields[j].value.filter((v: any) => Object.keys(v).indexOf(opt.key) >= 0);
                                                if (v.length > 0) {
                                                    field.value.push(v[0]);
                                                }
                                            }
                                        }

                                        if (count === field.options.length) {
                                            field.checked = true;
                                        }
                                        break;
                                    case "tree":
                                    case "typeahead":
                                        if (wrap.disableEmpty) {
                                            let oRFilledIndex = -1;
                                            for (let k = 0; k < wrap.fields.length; k++) {
                                                if (data[wrap.fields[k].name]) {
                                                    oRFilledIndex = k;
                                                }
                                            }
                                            if ((oRFilledIndex === -1 && j === 0) || oRFilledIndex === j) {
                                                field.show = true;
                                            } else {
                                                field.show = false;
                                            }
                                        }
                                        // Group Name in Output
                                        field.value = data[field.name] ? data[field.name].map((c: string) => {
                                            const cObj: any = {};
                                            cObj[field.codeKey] = c;
                                            const mv = (mapList[c.toUpperCase()] ? { ...mapList[c.toUpperCase()] } : { ...cObj }); // Common 
                                            if (data[custom_groups_key_txt]?.[field.name] && data[custom_groups_key_txt][field.name][c]) {
                                                mv[field.labelKey] = data[custom_groups_key_txt][field.name][c]?.toString();
                                                mv["top"] = true;
                                                mv["hide"] = true;
                                            }
                                            return mv;
                                        }).filter((v: any, i: number, a: any) => (!v?.showFor || props.customer.data_content.indexOf(v?.showFor?.subscription) >= 0) && a.findIndex((v2: any) => (v[field.codeKey] === v2[field.codeKey])) === i) : JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].value));
                                        // END
                                        break;
                                    case "textarea":
                                        field.value = data[field.name] !== undefined && data[field.name] !== null ? data[field.name] : JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].value));
                                        break;
                                    default:
                                        if (wrap.type === "option" && wrap.condition === "OR") {
                                            field.show = Object.keys(data).length === 0 || data[field.name] ? true : false;
                                        }

                                        field.value = data[field.name] !== undefined && data[field.name] !== null ? data[field.name] : JSON.parse(JSON.stringify(handleDefault().lists[q].wraps[i].fields[j].value || []));
                                }
                            }
                        }
                    }
                }
            }
        }
        setTemplateIp(prev);
        setTemplateJSON(prev);
    }, [handleDefault, props.customer, referenceDataRef])

    // Handler for loading saved, select template
    const getSavedTemplateData = useCallback((ev: any, isHistory: boolean): void => {

        let data: any;

        if (!isHistory) {
            console.log("getSavedTemplateData", ev.target.value);
            for (let i = 0; i < savedTemplates.length && ev.target.value.length > 0; i++) {
                if (savedTemplates[i]?.template_name === ev.target.value) {
                    data = { ...savedTemplates[i].request, template_name: ev.target.value };
                    break;
                }
            }
            if (ev.target.value === "") {
                data = {};
            }
        } else {
            data = ev;
        }
        setSelectedSavedTemplate(data)
    }, [savedTemplates, setSelectedSavedTemplate]);

    useEffect(() => {
        console.log("templateJSON Updated", templateJSON)
    }, [templateJSON]);

    useEffect(() => {
        setViewAs(customersRef.viewAs);
    }, [customersRef.viewAs]);

    useEffect(() => {
        setTemplateAsTxt(JSON.stringify(templates[props.params]));
        const hd = handleDefault();
        setTemplateIp({ ...hd });
        setTabIndex(0);
        setRes({
            pivotTable: {
                data: [...iiPivotHead, ...iiPivotBody],
                metrics: [],
                rows: [],
                cols: [],
                vals: []
            },
        });
    }, [props.params, handleDefault]);

    // initializer
    useEffect(() => {
        const hd = handleDefault();
        setTemplateIp({ ...hd });
        setTemplateJSON(hd);

        if (localStorage.getItem("bearer")) {
            dispatch(getAllSavedTemplatesAction());
            dispatch(getSavedTemplatesAction(hd.reportName.toUpperCase()));
        }
        setTabIndex(0);
        setIp({});
        setSelectedTemplate({ template_name: "" });

        return () => {
            // clean-up loaded template status
            dispatch({ type: 'TEMPLATES_DONE', data: { status: false } });
        }
    }, [templateAsTxt, handleDefault, dispatch, customersRef.viewAs])

    useLayoutEffect(() => {
        setTimeout(() => {
            const rends = window.document.querySelectorAll(".pvtUi tbody tr");
            if (rends.length > 0) {
                //rends[0].style.display = 'none';
            }

            const pvtUnused = window.document.querySelectorAll(".pvtUnused");
            if (pvtUnused.length > 0) {
                pvtUnused[0].style.display = 'none';
            }
        }, 500);

        setTimeout(() => {
            const TRs: any[] = window.document.querySelectorAll(".pvtTable thead tr");
            //console.log("PT:TRS:", TRs);
            const colors = {
                metrics: "#59CADD",
                cols: "#f5f8ea",
                rows: "#edf4ff"
            };
            for (let i = TRs.length - 2; i >= 0; i--) {
                for (let j = 0; j < TRs[i].children.length; j++) {
                    if (TRs[i].children[j].innerHTML.length > 0) {
                        TRs[i].children[j].style.background = colors.cols;
                        TRs[i].children[j].classList.remove("metrics");
                        TRs[i].children[j].classList.add("col");
                    }
                }
            }

            for (let i = TRs.length - 1; i >= 0; i--) {
                for (let j = 0; j < TRs[i].children.length && (TRs[i].children[0]?.innerHTML === "Metrics" || TRs[i].children[1]?.innerHTML === "Metrics"); j++) {
                    //console.log("PT:TRS:TH:", TRs[i].children[j].innerHTML);
                    if (TRs[i].children[j].innerHTML.length > 0) {
                        TRs[i].children[j].style.background = colors.metrics;
                        TRs[i].children[j].classList.add("metrics");
                    }
                }
            }

            const tTRs: any[] = window.document.querySelectorAll(".pvtTable tbody tr");
            for (let i = tTRs.length - 1; i >= 0; i--) {
                if (tTRs[i].children[0]?.innerHTML === "Totals") {
                    tTRs[i].style.display = "none";
                }
            }

            const bTRs: any[] = window.document.querySelectorAll(".pvtTable tbody tr .pvtRowLabel");
            for (let i = 0; i < bTRs.length; i++) {
                bTRs[i].style.background = colors.rows;
            }
        }, 50);
    });

    const disableTree = useCallback((values: any, options: any, label: string, key: string, childrenAttr: string): any => {
        return options.map((opt: any) => {
            if (!opt.canDisabled) {
                delete opt.disabled;
            } else {
                opt.disabled = true;
            }
            opt.className !== "proprietaryfields" && delete opt.className
            if (opt[childrenAttr]) {
                opt[childrenAttr] = disableTree(values, opt[childrenAttr], label, key, childrenAttr);
                if (values.toString() === opt[key]) {
                    opt.disabled = true;
                }
                if (opt[childrenAttr].filter((c: any) => c.disabled || c.className === "hideCheckbox").length > 0) {
                    opt.className = "hideCheckbox";
                }
                return opt;
            } else if (values.indexOf(opt[key]) >= 0) {
                opt.disabled = true;
                return opt;
            } else {
                return opt;
            }
        })
    }, []);

    const filterTreeRules = useCallback((field: any, keyType: "labelKey" | "codeKey") => {
        const res = [...[...field.value.map((v: any) => v[field[keyType]] || v)].filter((v: string) => {
            return (field.noTop ? field.options.filter((op: any) => !op.top) : getTreeNodes(field, field.options))
                .filter((o: any) => o[field[keyType]].indexOf(v) >= 0).length > 0;
        })];
        return res;
    }, []);

    useEffect(() => {
        setTemplateIp((prev: any) => {
            prev.lists.forEach((list: any, l: number) => {
                const currentWraps = list.wraps;
                if (list.mergeOnIndex >= 0) {
                    prev.lists[list.mergeOnIndex].wraps.forEach((wrap: any, i: number) => {
                        //console.log("WWW", wrap);
                        wrap.fields?.forEach((field: any, j: number) => {
                            disableTree(currentWraps[i].fields[j].value.map((v: any) => v.key), field.options, "label", "key", "children");
                            /*field.options.forEach((opt: any, k: number) => {
                                prev.lists[list.mergeOnIndex].wraps[i].fields[j].options[k].disabled = currentWraps[i].fields[j].value.indexOf(opt.key) >= 0 || currentWraps[i].fields[j].value.indexOf(opt.value) >= 0;
                            })*/
                        })
                    })
                }
            });

            //INTERACTIVE INSITES ONLY
            if (prev.reportName === 'INTERACTIVE') {
                setRes((rPrev: any) => {
                    let allMetricsValues: any[] = [], allRowValues: any[] = [], allColsValues: any[] = [];
                    prev.lists[prev.lists.length - 3].wraps.forEach((wrap: any) => {
                        if (wrap.fields) {
                            allMetricsValues = [
                                ...allMetricsValues,
                                ...filterTreeRules(wrap.fields[0], "labelKey")
                            ];
                        }
                    })
                    prev.lists[prev.lists.length - 2].wraps.forEach((wrap: any) => {
                        if (wrap.fields) {
                            allRowValues = [
                                ...allRowValues,
                                ...filterTreeRules(wrap.fields[0], "labelKey")
                            ];
                        }
                    })
                    prev.lists[prev.lists.length - 1].wraps.forEach((wrap: any) => {
                        if (wrap.fields) {
                            allColsValues = [
                                ...allColsValues,
                                ...filterTreeRules(wrap.fields[0], "labelKey")
                            ];
                        }
                    })
                    rPrev.pivotTable.metrics = [...allMetricsValues];
                    rPrev.pivotTable.data = [...iiPivotHead, ...iiPivotBody.filter((d: any, i: number) => {
                        return allMetricsValues.indexOf(d[d.length - 1]) >= 0
                    })];
                    rPrev.pivotTable.rows = [...allRowValues.filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i)];
                    rPrev.pivotTable.cols = [...allColsValues.filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i).filter((v: string) => v !== "Metrics"), "Metrics"];
                    return { ...rPrev };
                });

                document.querySelector('.pvtRows div')?.remove();
                document.querySelector('.pvtCols div')?.remove();

                setTimeout(() => {
                    let els: any = document.getElementsByClassName('pvtVal');
                    for (let ei = 0; ei < els.length; ei++) {
                        els[ei].innerHTML = FDS.numberFormatTxt(Math.round(random() * 3700), null, null);
                    }

                    els = document.getElementsByClassName('pvtAxisLabel');
                    for (let ei = 0; ei < els.length; ei++) {
                        els[ei].innerHTML = capitalize(els[ei].innerHTML.replace(/_/gi, " "));
                    }

                    els = document.querySelectorAll('.pvtColLabel.metrics');
                    for (let ei = 0; ei < els?.length; ei++) {
                        els[ei].innerHTML = capitalize(els[ei].innerHTML.replace(/_/gi, " "));
                    }

                    els = document.getElementsByClassName('pvtAttr');
                    for (let ei = 0; ei < els.length; ei++) {
                        els[ei].getElementsByClassName("pvtTriangle")?.[0]?.remove();
                        els[ei].innerHTML = capitalize(els[ei]?.textContent?.replace(/_/gi, " ") || "") + `<i id="${els[ei]?.textContent}" class="pivotClose fds-glyphs-cross2" />`;
                    }

                    els = document.getElementsByClassName("pivotClose");
                    for (let ei = 0; ei < els.length; ei++) {
                        els[ei].addEventListener("click", (ev: any) => {
                            const id = ev.target.id;
                            console.log(id, templateIp);
                            const tPrev = templateIp;
                            tPrev.lists[tPrev.lists.length - 2].wraps[0].fields[0].value = tPrev.lists[tPrev.lists.length - 2].wraps[0].fields[0].value.filter((r: any) => r.label.toLowerCase() !== id.toLowerCase());
                            tPrev.lists[tPrev.lists.length - 1].wraps[0].fields[0].value = tPrev.lists[tPrev.lists.length - 1].wraps[0].fields[0].value.filter((r: any) => r.label.toLowerCase() !== id.toLowerCase());
                            setTemplateIp({ ...tPrev });
                            setTemplateJSON(tPrev);
                        });
                    }


                    els = document.querySelectorAll('.pvtRows li');
                    if (els.length === 0) {
                        const node = document.createElement("div");
                        const textnode = document.createTextNode("New Row");
                        node.appendChild(textnode);
                        document.querySelector('.pvtRows')?.appendChild(node);
                    }

                    els = document.querySelectorAll('.pvtCols li');
                    if (els.length === 1) {
                        const node = document.createElement("div");
                        const textnode = document.createTextNode("New Column");
                        node.appendChild(textnode);
                        els[0].parentNode.insertBefore(node, els[0].parentNode.firstChild);
                    }

                    els = document.getElementsByClassName('pvtRenderers');
                    if (els.length > 0) {
                        els[0].innerHTML = "Rows / Cols";
                    }
                }, 100);
            }

            return prev;
        });
    }, [filterTreeRules, selectedTemplate, saveAs, templateIp, disableTree]);

    //onLoadRun
    useEffect(() => {
        // dispatch({ type: "LOADING", data: "Processing" });\
        if (templatesLoadedRef.status === true && props.onLoadRun) {
            // Check if favorite template exist for onLoadRun templates
            dispatch({ type: "STATUS", data: "" });
            const favTempIndex = savedTemplatesRef.findIndex((temp: any) => temp.favorite === "true" && temp.user_id === getOts()?.idToken?.sub)
            if (favTempIndex >= 0) {
                // load custom template and run 
                setSelectedSavedTemplate({ ...savedTemplatesRef[favTempIndex].request, template_name: savedTemplatesRef[favTempIndex].template_name });
                setTimeout(() => {
                    document.getElementById("runReport")?.click();
                }, 500)
            } else {
                setTimeout(() => {
                    document.getElementById("runReport")?.click();
                }, 500)
            }
            dispatch({ type: "LOADING", data: `Hang tight, your report is generating. May take up to 45 seconds.` });
        }
    }, [templatesLoadedRef.status, props.onLoadRun, dispatch, savedTemplatesRef, setSelectedSavedTemplate]);

    useEffect(() => {
        setSavedTemplates([...savedTemplatesRef]);
        return () => {
            // clean-up loaded template status
            dispatch({ type: 'TEMPLATES_DONE', data: { status: false } });
        }
    }, [savedTemplatesRef, dispatch]);

    useEffect(() => {
        setSavedAllTemplates([...savedAllTemplatesRef]);
    }, [savedAllTemplatesRef]);

    useEffect(() => {
        if (rerunTemplateRef && Object.keys(rerunTemplateRef).length > 0) {
            setTimeout(() => {
                getSavedTemplateData(JSON.parse(JSON.stringify(rerunTemplateRef)), true);
                dispatch({ type: "RERUN_TEMPLATE", data: {} });
            }, 200);
            window?.scrollTo(0, 0);
        }
    }, [rerunTemplateRef, getSavedTemplateData, dispatch]);

    return (
        <React.Fragment>
            <div className={`${templateJSON.filtersOnClick ? 'floatFiltersWrap hide' : ''}`} ref={filterWrap}>
                <Button id="runReport" className='d-none' onClick={(ev) => { ev.preventDefault(); prepareIP("A") }}></Button>
                <Row>
                    {templateJSON.lists.length > 1 ? <Col className="pe-0 minW" sm={2}>
                        <ListGroup className="arrowList ms-4 ps-2">
                            {templateJSON.lists?.map((list: any, i: number) => {
                                return (<ListGroup.Item className={!list.showFor || (props.customer?.data_content?.indexOf(list.showFor.subscription) >= 0) ? "" : "hide"} key={i} disabled={list?.disabled} active={tabIndex === i} onClick={() => setTabIndex(i)}>{list.label} {list.required ? <sup title="Required">*</sup> : list.requiredGroup ? <sup title={requiredGroupTxt}>{list.requiredGroup}*</sup> : null}</ListGroup.Item>)
                            })}
                        </ListGroup>
                    </Col> : null}
                    <Col className="arrowContent" sm={templateJSON.lists.length > 1 ? 10 : 12}>
                        {
                            templateJSON.info?.length > 0 ? <div className="pb-3">
                                <small className='info'>{templateJSON.info}</small>
                            </div> : null
                        }

                        {templateJSON.lists?.map((list: any, i: number) => {
                            return (
                                <React.Fragment key={list.label}>
                                    {tabIndex === i ? <div className={`card p-4 striped ${templateJSON.hideAllTopFilterOptions ? "pt-0" : ""} ${templateJSON.filtersOnTop ? "pt-3" : ""} top ${list.colorName} content`}>
                                        {templateJSON.hideAllTopFilterOptions ? null :
                                            <>
                                                <Form.Group className="form-group saved-reports pb-3" controlId="savedReports">
                                                    <Form.Label>Saved Templates</Form.Label>
                                                    <div className="select-flex">
                                                        <div className="select me-3">
                                                            <Form.Select as="select" aria-label='Select saved template' name="savedReportTemplates" value={selectedTemplate.template_name} disabled={savedTemplates.length > 0 ? false : true} onChange={(ev: any) => getSavedTemplateData(ev, false)}>
                                                                <option value="">{savedTemplates?.length > 0 ? "-- Select Report Template --" : "No Saved Reports"}</option>
                                                                {savedTemplates.filter((a: any) => a.shared === "0").sort((a: any, b: any) => a.template_name > b.template_name || a.scheduled > b.scheduled ? 1 : -1).map((stemp: any, i: number) => {
                                                                    return stemp.favorite && stemp.favorite === "true" && stemp.user_id === getOts()?.idToken?.sub ? <option key={i} value={stemp.template_name}> &#9733;{stemp.template_name}</option> : <option key={i} value={stemp.template_name}>{stemp.template_name}</option>
                                                                })}
                                                                {
                                                                    savedTemplates.filter((a: any) => a.user_org === Const.ARC_ROLE && a.shared !== "0").length > 0 ?
                                                                        <optgroup label='Shared with ARC'>
                                                                            {savedTemplates.filter((a: any) => a.user_org === Const.ARC_ROLE && a.shared !== "0").sort((a: any, b: any) => a.template_name > b.template_name ? 1 : -1).map((stemp: any, i: number) => {
                                                                                return stemp.favorite && stemp.favorite === "true" && stemp.user_id === getOts()?.idToken?.sub ? <option key={i} value={stemp.template_name}> &#9733;{stemp.template_name}</option> : <option key={i} value={stemp.template_name}>{stemp.template_name}</option>
                                                                            })}
                                                                        </optgroup> :
                                                                        null
                                                                }
                                                                {
                                                                    savedTemplates.filter((a: any) => a.user_org !== Const.ARC_ROLE && a.shared !== "0").length > 0 ?
                                                                        <optgroup label={`Shared ${viewAs.orgName ? ("with " + viewAs.orgName) : ""}`}>
                                                                            {
                                                                                savedTemplates.filter((a: any) => a.user_org !== Const.ARC_ROLE && a.shared !== "0").sort((a: any, b: any) => a.template_name > b.template_name ? 1 : -1).map((stemp: any, i: number) => {
                                                                                    return stemp.favorite && stemp.favorite === "true" && stemp.user_id === getOts()?.idToken?.sub ? <option key={i} value={stemp.template_name}> &#9733;{stemp.template_name}</option> : <option key={i} value={stemp.template_name}>{stemp.template_name}</option>
                                                                                })
                                                                            }
                                                                        </optgroup> :
                                                                        null
                                                                }
                                                            </Form.Select>
                                                        </div>
                                                        <div className='topRunButtons'>
                                                            <Dropdown className="me-3" as={ButtonGroup} id="previewReport">
                                                                <Button id="runReport" data-testid="runReport" onClick={(ev) => { ev.preventDefault(); prepareIP("A") }} variant="primary" disabled={
                                                                    (res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 0) &&
                                                                    (res.pivotTable.metrics.length === 0 || res.pivotTable.rows.length + res.pivotTable.cols.length === 1)
                                                                }>Run Report</Button>
                                                                <Dropdown.Toggle split variant="primary" aria-label="dropdown arrow" data-testid="runReportDropdown" disabled={
                                                                    (res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 0) &&
                                                                    (res.pivotTable.metrics.length === 0 || res.pivotTable.rows.length + res.pivotTable.cols.length === 1)
                                                                } />
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item data-testid="schedule" onClick={(ev) => { ev.preventDefault(); prepareIP("S") }}><i className="fds-glyphs-clock3 me-2" /> Schedule</Dropdown.Item>
                                                                    <Dropdown.Item data-testid="download" onClick={(ev) => { ev.preventDefault(); prepareIP("D") }}><i className="fds-glyphs-download me-2" />Download</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <Button
                                                                className='cursor viewall ms-auto'
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    getSavedTemplateData({ target: { value: "" } }, false);
                                                                    setSelectedTemplate((prev: any) => {
                                                                        return { ...prev, template_name: "" };
                                                                    });
                                                                }}>Clear All</Button>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <hr />
                                            </>
                                        }
                                        <div><small>{list.info}</small></div>
                                        <DynamicForm
                                            customer={props.customer}
                                            template={{ reportName: templateJSON.reportName, wraps: list.wraps, templateName: selectedTemplate.template_name }}
                                            onChange={(temp: any) => {
                                                const prev: any = templateIp;
                                                prev.lists[i].wraps = [...temp.wraps];
                                                setTemplateIp({ ...prev });
                                                prepareIP("I");
                                                if (templateJSON.onChangeSubmit) {
                                                    prepareIP("A");
                                                }
                                            }} />
                                        {props.customer?.data_source?.indexOf('GLOBAL') >= 0 && !templateJSON.onChangeSubmit && <div className='p-3'></div>}
                                        {templateJSON.onChangeSubmit ? null :
                                            <div className="mt-3 d-flex justify-content-end prevNextSpace">
                                                {i > 0 ? <Button className="me-auto" variant="secondary" onClick={() => {
                                                    let nextIndex: number = tabIndex - 1
                                                    while (nextIndex < templateJSON.lists.length && templateJSON.lists[nextIndex]?.showFor && props.customer?.data_content?.indexOf(templateJSON.lists[nextIndex]?.showFor?.subscription) < 0) { nextIndex-- }
                                                    setTabIndex(nextIndex);
                                                }}
                                                >Previous</Button> : null}
                                                {i < templateIp.lists.length - 1 ? <Button variant="primary" onClick={() => {
                                                    let nextIndex: number = tabIndex + 1
                                                    while (nextIndex < templateJSON.lists.length && templateJSON.lists[nextIndex]?.showFor && props.customer?.data_content?.indexOf(templateJSON.lists[nextIndex]?.showFor?.subscription) < 0) { nextIndex++ }
                                                    setTabIndex(nextIndex);
                                                }}
                                                >Next</Button>
                                                    : null}
                                            </div>
                                        }

                                        {res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 1 ? <div className="card mt-3" style={{ overflow: 'auto' }}>
                                            <React.Fragment>
                                                <div className="p-3">
                                                    <h3>Preview Template</h3>
                                                    <small>Adjust the template column and row fields below by clicking to grab the field name, dragging it to its new position, and releasing it to save changes. Adding/removing must be done using the sections above.</small>
                                                </div>
                                                <div className='watermark'>Sample Data</div>
                                                <PivotTableUI
                                                    rendererName="Table"
                                                    onChange={async (s: any) => {
                                                        // For React 18 Only
                                                        const rowChange = res.pivotTable.rows.filter(x => !s.rows.includes(x));
                                                        const colChange = res.pivotTable.cols.filter(x => !s.cols.includes(x));

                                                        if (rowChange.length === 0 && colChange.length > 0) {
                                                            changes.cols = [...colChange];
                                                        } else if (colChange.length === 0 && rowChange.length > 0) {
                                                            changes.rows = [...rowChange];
                                                        } else if (rowChange.length + colChange.length === 0) {
                                                            s.rows = s.rows.filter((x: any) => !changes.rows.includes(x));
                                                            s.cols = s.cols.filter((x: any) => !changes.cols.includes(x));
                                                            changes.rows.length = 0;
                                                            changes.cols.length = 0;
                                                        }
                                                        // END: For React 18 Only

                                                        const tPrev: any = templateIp;
                                                        const field = tPrev.lists[tPrev.lists.length - 2].wraps[0].fields[0];
                                                        const ls = getTreeNodes(field, field.options);

                                                        const rcMap: any = {};
                                                        // Convert to map from options
                                                        for (let i = 0; i < ls?.length; i++) {
                                                            if (!ls[i][field.labelKey]) {
                                                                continue;
                                                            }
                                                            rcMap[ls[i][field.labelKey]] = ls[i];
                                                        }

                                                        tPrev.lists[tPrev.lists.length - 2].wraps[0].fields[0].value = s.rows
                                                            .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i)
                                                            .filter((v: string) => v !== "Metrics" && rcMap[v])
                                                            .map((item: string) => {
                                                                return { label: rcMap[item]?.[field.labelKey], key: rcMap[item]?.[field.codeKey] }
                                                            });

                                                        tPrev.lists[tPrev.lists.length - 1].wraps[0].fields[0].value = s.cols
                                                            .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i)
                                                            .filter((v: string) => v !== "Metrics" && rcMap[v])
                                                            .map((item: string) => {
                                                                return { label: rcMap[item]?.[field.labelKey], key: rcMap[item]?.[field.codeKey] }
                                                            });

                                                        setRes((prev: any) => {
                                                            prev["pivotTable"].rows = s.rows;
                                                            prev["pivotTable"].cols = s.cols;
                                                            return prev;
                                                        });
                                                        setTemplateIp({ ...tPrev });
                                                        setTemplateJSON(tPrev);
                                                    }}
                                                    {...res.pivotTable}
                                                />
                                            </React.Fragment>
                                        </div> : null}

                                        {templateJSON.onChangeSubmit ? null :
                                            <Row className="mt-3 pt-3" style={{ borderTop: "1px solid #ccc" }}>
                                                {templateIp.lists.length > 1 ? <Col className="minW" sm={2}></Col> : null}
                                                <Col className="actionBtnsWrap d-flex justify-content-end" sm={templateIp.lists.length > 1 ? 10 : 12}>
                                                    <Button
                                                        className='cursor viewall me-auto'
                                                        variant="secondary"
                                                        onClick={() => {
                                                            getSavedTemplateData({ target: { value: "" } }, false);
                                                            setSelectedTemplate((prev: any) => {
                                                                return { ...prev, template_name: "" };
                                                            });
                                                        }}>Clear All</Button>
                                                    <Dropdown className="ms-3 me-3" as={ButtonGroup} id="previewReport">
                                                        <Button id="runReport" onClick={(ev) => { ev.preventDefault(); prepareIP("A") }} variant="primary" disabled={
                                                            (res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 0) &&
                                                            (res.pivotTable.metrics.length === 0 || res.pivotTable.rows.length + res.pivotTable.cols.length === 1)
                                                        }>Run Report</Button>
                                                        <Dropdown.Toggle split variant="primary" aria-label="dropdown arrow" disabled={
                                                            (res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 0) &&
                                                            (res.pivotTable.metrics.length === 0 || res.pivotTable.rows.length + res.pivotTable.cols.length === 1)
                                                        } />
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item data-testid="schedule" onClick={(ev) => { ev.preventDefault(); prepareIP("S") }}><i className="fds-glyphs-clock3 me-2" /> Schedule</Dropdown.Item>
                                                            <Dropdown.Item data-testid="download" onClick={(ev) => { ev.preventDefault(); prepareIP("D") }}><i className="fds-glyphs-download me-2" />Download</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {templateJSON.hideSavedTemplates ? null :
                                                        <Button id="saveTemplate" variant="primary"
                                                            disabled={(res.pivotTable.metrics.length + res.pivotTable.rows.length + res.pivotTable.cols.length > 0) && (res.pivotTable.metrics.length === 0 || res.pivotTable.rows.length + res.pivotTable.cols.length === 1) && props.params === "INTERACTIVE"}
                                                            onClick={() => { prepareIP("T") }}>
                                                            Save Template
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </div> : null}
                                </React.Fragment>
                            )
                        })}
                    </Col>
                </Row>
            </div>
            {templateJSON.filtersOnClick &&
                <div className='right filtersToggleBtn'>
                    <Button variant='secondary' onClick={() => {
                        filterWrap.current.classList.toggle("hide");
                    }}>Filters</Button>
                </div>
            }
            {showSchedularModal && Object.keys(Refip).length ? <Schedular ip={ip} type={props.params} refinedIp={Refip} savedTemplates={savedAllTemplates} selectedTemplate={selectedTemplate} show={showSchedularModal} onHide={setShowSchedularModal} /> : null}
            <SaveTemplate ip={ip} type={props.params} savedTemplates={savedAllTemplates} selectedTemplate={selectedTemplate} saveAs={saveAs} show={showTemplateModal} onHide={setShowTemplateModal} />
        </React.Fragment>
    );
};