import React, { useState, useRef, useEffect } from 'react'
import { Button, Modal, Form, Col, Row, OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap'
import { setFeedback, uploadFile } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux';
import { getOts } from '../../okta/ls';

export const Feedback = (props: any) => {
    const maxFileSize = 5
    const charLimit = 1500
    const dispatch = useDispatch();
    const loginRef = useSelector((state: any) => state.loginRef)
    const msgRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;
    const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [showModal, setShowModal] = useState(false)
    const [user, setUser] = useState(Object);
    const [, setFileSize] = useState(maxFileSize)
    const [isValidNumber, setIsValidNumber] = useState(true)
    const [progress, setProgress] = useState(0)
    const [contactCheck, setContactCheck] = useState(false)

    let initialForm = { page: props.page }
    const [feedbackForm, setFeedbackForm]: any = useState(initialForm)

    const handleContactChange = (e: any) => {
        e.target.value.length > 0 && e.target.value.length < 10 ? setIsValidNumber(false) : setIsValidNumber(true)
        e.target.validity.patternMismatch ? setFeedback(feedbackForm) : setFeedbackForm({ ...feedbackForm, [e.target.name]: e.target.value })
    }

    const handleChange = (e: any) => {
        e.preventDefault()
        if (e.target.files?.length > 0) {
            const allowedFiles = ['image/jpeg', 'image/png', 'application/pdf', "application/msword", "application/vnd.ms-excel", "application/xls", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "text/csv"]
            if (allowedFiles.indexOf(e.target.files[0]?.type) >= 0) {
                const fileInMb = e.target.files[0]?.size / (1024 * 1024)
                if (fileInMb) {
                    if (fileInMb < maxFileSize) {
                        setProgress(0)
                        dispatch(uploadFile(e.target.files, setProgress))
                        setFileSize(parseFloat(fileInMb.toFixed(2)))
                        setFeedbackForm({ ...feedbackForm, attachment: e.target.files[0] })
                    } else {
                        dispatch({ type: "STATUS", data: { type: "WARNING", title: "Large file size ", message: `File size is greater than ${maxFileSize} Mb, please reduce file size` } });
                    }
                }
            } else {
                dispatch({ type: "STATUS", data: { type: "WARNING", title: "File Type Not Allowed ", message: `Selected file type is not supported` } });
            }
        }
        if (!e.target.files) {
            if (e.target.validity) e.target.validity.valid ? setFeedbackForm({ ...feedbackForm, [e.target.name]: e.target.value }) : setFeedbackForm({ ...feedbackForm, [e.target.name]: feedbackForm.contact_number })
            else setFeedbackForm({ ...feedbackForm, [e.target.name]: e.target.value })
        }
    }

    const handelSetFeedback = () => {
        const res: any = dispatch(setFeedback({ ...feedbackForm, userName: user.claims.name, email: user.claims.email }))
        res?.then((res: any) => {
            if (res?.status === 200) {
                setFeedbackForm(initialForm)
                setFileSize(maxFileSize)
                setIsValidNumber(true)
                setProgress(0)
                setContactCheck(false)
            }
        })
    }

    const removeFile = () => {
        setFileSize(maxFileSize)
        setProgress(0)
        setFeedbackForm(Object.fromEntries(Object.entries(feedbackForm).filter(([key]) => key !== "attachment")))
    }

    const enableRequest = () => {
        if (feedbackForm.message && isValidNumber && feedbackForm.subject?.length && !contactCheck) return false
        else if ((feedbackForm.message && isValidNumber && feedbackForm.subject?.length && contactCheck && feedbackForm.contactNbr?.length)) return false
        return true
    }

    useEffect(() => {
        Object.keys(getOts()).length > 0 ? setUser(getOts()?.idToken) : setUser({})
    }, [loginRef])

    return (
        <React.Fragment>
            <Row id='feedBackBtn'>
                <Col>
                    {Object.keys(user)?.length > 0 && <Button data-testid="feedback-button" onClick={() => setShowModal(true)} className='rounded-0'>Send Feedback</Button>}
                    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={() => { setShowModal(false); setFeedbackForm(initialForm); setFileSize(maxFileSize); setIsValidNumber(true); setProgress(0); setContactCheck(false) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>We love to hear your feedback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='feedBackBtn'>
                                <Form.Group controlId="subject" className="mt-2">
                                    <Form.Label>Subject <OverlayTrigger placement="auto" overlay={<Tooltip>{`character of limit 100`}</Tooltip>}><i className="fds-glyphs-info infoTxt" /></OverlayTrigger> *</Form.Label>
                                    <Form.Control aria-label='Subject' type="text" name="subject" maxLength={100} autoComplete="off" value={feedbackForm.subject || ""} onChange={(ev: any) => handleChange(ev)} placeholder="Subject" />
                                </Form.Group>
                                <Form.Group className="form-group mt-3" controlId="feedback_text">
                                    <Form.Label>Please enter the details in the box below and submit. <OverlayTrigger placement="auto" overlay={<Tooltip>{`character of limit ${charLimit}`}</Tooltip>}><i className="fds-glyphs-info infoTxt" /></OverlayTrigger></Form.Label>
                                    <Form.Control aria-label='Feedback Message' ref={msgRef} as="textarea" maxLength={charLimit} rows={4} name="message" autoComplete="off" value={feedbackForm.message || ""} onChange={(e) => handleChange(e)} placeholder="Enter your feedback message" />
                                </Form.Group>


                                <Form.Group controlId="formFile" className="form-group mt-3">
                                    <Form.Label>Upload Document <em>(optional)</em></Form.Label>
                                    <Form.Control data-testid="upload-input" ref={fileRef} type="file" className="d-none" name='attachment' onChange={(e) => handleChange(e)} />

                                    <div style={{ border: "#949494 0.0625rem solid", borderRadius: "0.25rem" }}>
                                        <Button style={{ borderRadius: "0px" }} aria-label='Upload file' className="me-2 btn btn-primary" onClick={() => fileRef.current.click()}>Select File</Button>
                                        {feedbackForm.attachment ? <div className="fds-tag primary">{feedbackForm.attachment.name}<i className="fds-glyphs-clear" data-testid="removeFile" onClick={() => removeFile()}></i></div> : "No file selected"}
                                    </div>
                                    <Form.Text>Acceptable file type .doc .docx, .xls .xlsx, .jpeg, .pdf, .csv</Form.Text> <br />
                                    <Form.Text>{maxFileSize} MB max file size</Form.Text>
                                    <ProgressBar now={progress} label={`${progress} %`} className='mt-3' />

                                </Form.Group>

                                <Form.Check className='mt-3' type="checkbox" name="check-contact" checked={contactCheck} label="Receive a call back about your feedback" onChange={() => { setContactCheck(!contactCheck); setFeedbackForm({ ...feedbackForm, contactNbr: "" }) }} />
                                {contactCheck &&
                                    <Form.Group className="form-group mt-2" controlId="contactNbr">
                                        <Form.Label>Contact Number</Form.Label>
                                        <Form.Control aria-label='Contact Number' type="tel" maxLength={10} pattern="[0-9]*" name="contactNbr" autoComplete="off" onInput={(e) => handleContactChange(e)} value={feedbackForm.contactNbr || ""} />
                                        {!isValidNumber && <Form.Text className="danger pb-1">Not a valid Number</Form.Text>}
                                    </Form.Group>
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button data-testid="sendFeedbackButton" aria-label='Send feedback message' variant="primary" type='submit' onClick={() => handelSetFeedback()} disabled={enableRequest()}>
                                Send Feedback
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row >

        </React.Fragment >

    )
}
export default React.memo(Feedback)
