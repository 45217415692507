import React from 'react';
import ReactDOM from "react-dom/client";
import { HashRouter } from 'react-router-dom';
import './index.scss';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { auth0Config } from './okta/config';
import { Auth0Provider } from '@auth0/auth0-react';

import store from './store';

declare const window: any;

//console.log("CONVERT KEY", Buffer.from("key").toString('base64'))

const DOGS: any = {
  "DEV": "cHViODY4YjAxN2Y4MDExMmYzNzE3N2E4YjdmNTFhZjgwNjY=",
  "QA": "cHViMmY4OGZhMGMwOTA4MGQzZmUzNTU4ZTk0YzI0OTA4YzU=",
  "UAT": "cHViMzg4YzM3NGY4N2Y1YzA1YzBmN2Q5ZjdhMzc5ODllYzk=",
  "PROD": "cHViY2FhNDRkY2JkNzVhMWFiOGYwMGMzMTJlNmZiYzkxZTQ="
}

const DOG = Buffer.from(DOGS[window.ENV.type], 'base64').toString('ascii')

// for datadog monitoring
if (window.ENV.DATA_DOG_APP_ID && DOG) {
  datadogRum.init({
    applicationId: window.ENV.DATA_DOG_APP_ID,
    clientToken: DOG,
    site: 'datadoghq.com',
    sampleRate: 100,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Auth0Provider {
    ...auth0Config}
  >
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
