import React from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth0, LocalStorageCache } from "@auth0/auth0-react";
import { clearLocalStorage } from "../components/utils";

declare const window: any;

const Login = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, logout, getIdTokenClaims, loginWithRedirect, error, user } = useAuth0();

  if (error) {
    console.log("error logging in", error);
    clearLocalStorage();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  if (isAuthenticated) {
    setTimeout(async () => {
      try {
        var tokens: any = await getIdTokenClaims();
        if (tokens != null) {
          localStorage.setItem("isTimeoutShowed", "false");
          dispatch({ type: "LOGIN", data: tokens });

          //Refresh Token:
          const refresh_token = new LocalStorageCache();
          const key = refresh_token.allKeys().find((key) => key.includes("auth0spajs") && key.includes(window.ENV.oktaConfig.domain));
          const refresh_token_value: any = refresh_token.get(key || "");
          const finalRefreshToken = refresh_token_value?.body?.refresh_token;
          localStorage.setItem("bearer", tokens.__raw);
          localStorage.setItem("refresh_token", finalRefreshToken);
          localStorage.setItem("expiresAt", tokens.exp);
          loginWithRedirect(tokens);
        }
      } catch (e) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    }, 500);
  } else {
    loginWithRedirect();
  }

  if (!user) {
    return null;
  }

  return isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Redirect to={{ pathname: "/logout" }} />
  );
};
export default Login;
