import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { Row, Col, Breadcrumb, Alert, Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as FDS from '@arctravel/react-fds';
import { DynamicPagination } from '../../utils/pagination/DynamicPagination';
import { processResponse } from "../../utils";

import './index.scss';

declare const window: any;

export const ARCCIDataStatus = (props: any) => {
    const dispatch = useDispatch();
    const [isTMC, setIsTMC] = useState(0);
    const [response, setResponse] = useState({ page1: { results: [] }, page2: { results: [] } });
    const [corps, setCorps]: any = useState({});
    const [tmcs, setTmcs]: any = useState({});

    const itemPerPage = 500, itemPerPage3 = 50;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage3, setCurrentPage3] = useState(1);

    const [modalData, setModalData]: any = useState({
        data: []
    });

    const getTCV = (row: any) => {
        console.log(row);
        dispatch({ type: "LOADING", data: "Downloading data" });
        axios.post(window.ENV.reportsDomain, {
            "reportType": "ARCCIDS",
            "tc_validation_chart": true,
            "corp_id": row.tmcs.ADC_CARR_CORP_ID,
            "agent_id": row.tmc
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            setModalData({ data: data.sort((a: any, b: any) => new Date(a["ISSUE_MONTH"]) > new Date(b["ISSUE_MONTH"]) ? -1 : 1) });
            dispatch({ type: "LOADING", data: "" });
            if (data.length === 0) {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "No Data Available", message: "Tour Code Validation data is not available for the selected Corporation and Agent." } });
            }
        }).catch((error: AxiosError) => {
            setModalData({ data: [] });
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Request Failed", message: "Tour Code Validation Request Failed. Contact system administrator." } });
        });
    }

    const getTCVColor = (value: number) => {
        return value >= 0.75 ?
            'success' :
            (value >= 0.50 && value < 0.75) ?
                'warning' :
                (value > 0 && value < 0.49) ?
                    'danger' :
                    'secondary';
    }

    const getYNColor = (value: string) => {
        return value?.toLowerCase() === 'yes' ?
            'success' :
            value?.toLowerCase() === 'no' ?
                'danger' :
                'secondary';
    }

    const getMSColor = (value: string) => {
        return value?.toLowerCase() === "complete"
            ? 'success d100' :
            value?.toLowerCase() === "incomplete" ?
                'danger d50' :
                'secondary d100';
    }

    const getCorpStatusColor = (value: string) => {
        return value?.toLowerCase().indexOf("no data") >= 0 ?
            "danger" :
            value?.toLowerCase().indexOf("incomplete data") >= 0 ?
                "warning" :
                value?.toLowerCase().indexOf("complete data") >= 0 ?
                    "success" :
                    null;
    }

    const getTMCStatusColor = (value: string) => {
        return value?.toLowerCase().indexOf("no data") >= 0 ?
            "danger" :
            value?.toLowerCase().indexOf("receiving incomplete") >= 0 ?
                "warning" :
                value?.toLowerCase().indexOf("receiving complete") >= 0 ?
                    "success" :
                    null;
    }

    const statusTable = (r: any, index?: number) => {
        return (
            <>
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={2}>Receiving Data</Col>
                    <Col sm={2}>Current Data</Col>
                    <Col sm={2}>Historical Data</Col>
                    <Col sm={2}>Other Airline Data</Col>
                    <Col sm={2}>
                        {r.tmc?.length > 0 && isTMC === 1 ?
                            <Button variant='link'
                                className='p-0'
                                data-testid={"tcv" + index}
                                onClick={() => {
                                    getTCV(r);
                                }}>
                                <Row>
                                    <Col>Tour Code <br /> Validation</Col>
                                    <Col className='tcvIcon p-0 m-0' sm={1}><i className='fds-glyphs-file-presentation' /></Col>
                                </Row>
                            </Button> :
                            <span>Tour Code Validation</span>
                        }
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={2}>U.S. Point of Sale</Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.ASP_ANY_DATA)}`}></span>
                        {FDS.valueOrDash(r.ASP_ANY_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.ASP_UPDATED_DATA)}`}></span>
                        {FDS.valueOrDash(["no", "not needed"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_UPDATED_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.ASP_HISTORICAL_DATA)}`}></span>
                        <span>{FDS.valueOrDash(["no", "not needed"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_HISTORICAL_DATA)}</span>                                                </Col>
                    <Col sm={2}>
                        <span className={`dot ${getMSColor(["no", "not needed"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_MARKET_SHARE)}`}></span>
                        <span className='text-capitalize'>{FDS.valueOrDash(["no", "not needed"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_MARKET_SHARE)}</span>                                                </Col>
                    <Col sm={2}>
                        <span className={`dot d100 ${getTCVColor(r.ASP_TOTAL_RATE_LTM || 0)}`}></span>
                        {FDS.valueOrDash(r.ASP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.ASP_TOTAL_RATE_LTM * 100, 2, '%') : r.ASP_TOTAL_RATE_LTM)}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={2}>Non-U.S. Point of Sale</Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.BSP_ANY_DATA)}`}></span>
                        {FDS.valueOrDash(r.BSP_ANY_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.BSP_UPDATED_DATA)}`}></span>
                        {FDS.valueOrDash(["no", "not needed"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_UPDATED_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${getYNColor(r.BSP_HISTORICAL_DATA)}`}></span>
                        <span>{FDS.valueOrDash(["no", "not needed"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_HISTORICAL_DATA)}</span>
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${getMSColor(["no", "not needed"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_MARKET_SHARE)}`}></span>
                        <span className='text-capitalize'>{FDS.valueOrDash(["no", "not needed"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_MARKET_SHARE)}</span>
                    </Col>
                    <Col sm={2}>
                        <span className={`dot d100 ${getTCVColor(r.BSP_TOTAL_RATE_LTM || 0)}`}></span>
                        {FDS.valueOrDash(r.BSP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.BSP_TOTAL_RATE_LTM * 100, 2, '%') : r.BSP_TOTAL_RATE_LTM)}
                    </Col>
                </Row>
            </>
        )
    }

    useEffect(() => {
        console.log("KKK", props.data)
        setResponse(Object.keys(props.data).length === 0 ? { page1: { results: [] }, page2: { results: [] } } : props.data);
    }, [props.data]);

    return (
        <>
            {
                response.page1?.results?.length > 0 ?
                    <>
                        <Breadcrumb className='mt-3 ms-2'>
                            <Breadcrumb.Item href="#" active={isTMC === 0} onClick={() => {
                                const cl = window.document.getElementById("reportPageLeftToggleRef")?.classList;
                                cl?.add("static");
                                cl?.remove("min");
                                setCurrentPage3(1);
                                setTimeout(() => {
                                    setIsTMC(0);
                                }, 300);
                            }}>Data Status List</Breadcrumb.Item>
                            {isTMC >= 1 ? <Breadcrumb.Item href="#" active={isTMC === 1} onClick={() => { setIsTMC(1); setCurrentPage3(1); }}>{tmcs?.ADC_CORP_NM || "TMC"}</Breadcrumb.Item> : null}
                            {isTMC >= 2 ? <Breadcrumb.Item active={isTMC === 2}>{corps?.ADC_AGENCY_NAME || "CORPS"}</Breadcrumb.Item> : null}
                        </Breadcrumb>
                        {isTMC === 0 ?
                            <>
                                <Row>
                                    <Col className='ms-2 pt-3'>
                                        Records
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + 1, 0, null)}</b> -
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + itemPerPage < (response.page1?.results?.length || 0) ? (currentPage - 1) * itemPerPage + itemPerPage : (response.page1?.results?.length || 0), 0, null)}</b> of
                                        <b> {FDS.numberFormat(response.page1?.results?.length || 0, 0, null)}</b>
                                    </Col>
                                    <Col className='me-2 right'>
                                        <DynamicPagination className='mb-0' currentPage={currentPage} itemPerPage={itemPerPage} totalItems={response.page1?.results?.length || 0} onPageChange={(page: number) => setCurrentPage(page)} />
                                    </Col>
                                </Row>
                                <Row className="arcciCorpWrap m-2">
                                    <Col>
                                        {
                                            [...(response.page1?.results || [])]?.slice((currentPage - 1) * itemPerPage, (currentPage - 1) * itemPerPage + itemPerPage)
                                                .map((r: any, ri: number) => {
                                                    return (
                                                        <Row className='ciRow' role="button" key={ri} data-testid={"p1CiRow" + ri} onClick={() => {
                                                            const rpltr = window.document.getElementById("reportPageLeftToggleRef");
                                                            if (rpltr) {
                                                                const cl = rpltr?.classList;
                                                                cl?.remove("static");
                                                                cl?.add("min");
                                                            }
                                                            setIsTMC(1);
                                                            setTmcs((prev: any) => {
                                                                return { ...r, tmcs: [...response.page2?.results?.filter((tmc: any) => r.ADC_CORP_NM === tmc.ADC_CORP_NM)] }
                                                            });
                                                        }}>
                                                            <Col md={6}>
                                                                <div>
                                                                    <span className={`dot ${getCorpStatusColor(r.corp_data_status)}`} title={r.corp_data_status}></span>
                                                                    <b>{FDS.valueOrDash(r.ADC_CORP_NM)}</b>
                                                                    <small className='type-body-secondary-on-light ms-2 capitalize'>Corp</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div><label>Corporate Id</label></div>
                                                                <div className='type-body-secondary-on-light'><small>{FDS.valueOrDash(r.ADC_CARR_CORP_ID)}</small></div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div><label>DRA Count</label></div>
                                                                <div className='type-body-secondary-on-light'><small>{FDS.valueOrDash(r.count)}</small></div>
                                                            </Col>
                                                            <Col className='contactWrap hide' md={4}>
                                                                <Row>
                                                                    <Col className='initialWrap'>
                                                                        <div className='initial disabled'>{r.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className='text-capitalize'>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER__C?.toLowerCase())}</div>
                                                                        <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(null)}</small></div>
                                                                        <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='ms-2'>
                                        Records
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + 1, 0, null)}</b> -
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + itemPerPage < (response.page1?.results?.length || 0) ? (currentPage - 1) * itemPerPage + itemPerPage : (response.page1?.results?.length || 0), 0, null)}</b> of
                                        <b> {FDS.numberFormat(response.page1?.results?.length || 0, 0, null)}</b>
                                    </Col>
                                    <Col className='me-2 right'>
                                        <DynamicPagination currentPage={currentPage} itemPerPage={itemPerPage} totalItems={response.page1?.results?.length || 0} onPageChange={(page: number) => setCurrentPage(page)} />
                                    </Col>
                                </Row>
                            </> : null
                        }

                        {isTMC === 1 ?
                            <Row className="m-2 p-3" style={{ background: "white" }}>
                                <Col>
                                    <Row className='arcciCorpWrap mb-3 pb-2' style={{ background: "white", border: "none" }}>
                                        <Col md={9}>
                                            <Row>
                                                <Col style={{ maxWidth: "18px" }}>
                                                    <span className={`dot xl ${getCorpStatusColor(tmcs.corp_data_status)} mt-3 mr-2`} title={tmcs.corp_data_status}></span>
                                                </Col>
                                                <Col>
                                                    <h2 className='mb-0'>{FDS.valueOrDash(tmcs.ADC_CORP_NM)}</h2>
                                                    <div className='lessMargin type-body-secondary-on-light'><small>Corporate ID: {FDS.valueOrDash(tmcs.ADC_CARR_CORP_ID)}</small></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='contactWrap hide'>
                                            <Row>
                                                <Col className='initialWrap'>
                                                    <div className='initial disabled'>{tmcs.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                </Col>
                                                <Col>
                                                    <div>{FDS.valueOrDash(tmcs.COMPANY_TRAVEL_MANAGER__C)}</div>
                                                    <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                    <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(tmcs.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        tmcs.tmcs?.length > 0 ? [...(tmcs.tmcs || [])]?.map((r: any, ri: number) => {
                                            return (
                                                <Row key={ri} className='arcciCorpWrap tmc mb-3 boxShadow'>
                                                    <Col className='tmcLeft' md={3} role="button" data-testid={"p2CiRow" + ri} onClick={() => {
                                                        setIsTMC(2);
                                                        setCorps((prev: any) => {
                                                            return { ...r, corps: [...response.page1?.results?.filter((corp: any) => r.ADC_CORP_NM === corp.ADC_CORP_NM)], tmcs: [...response.page2?.results?.filter((corp: any) => r.ADC_AGENCY_NAME === corp.ADC_AGENCY_NAME)] }
                                                        });
                                                    }}>
                                                        <Row>
                                                            <Col style={{ maxWidth: "18px" }}>
                                                                <span className={`dot lg ${getTMCStatusColor(r.DATA_STATUS)}`} title={r.DATA_STATUS}></span>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <b>{FDS.valueOrDash(r.ADC_AGENCY_NAME)}</b>
                                                                </div>
                                                                <div><small>Agent Id: {FDS.valueOrDash(r.tmc)}</small></div>
                                                                <div className='lessMargin type-body-secondary-on-light'><small>TMC</small></div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <div className='mb-1'><b><small>TMC Contact</small></b></div>
                                                        <Row className='contactWrap'>
                                                            <Col className='initialWrap'>
                                                                <div className='initial disabled'>
                                                                    {r.AGENCY_CONTACT_NAME__C?.match(/\b(\w)/g)?.join('')}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>{FDS.valueOrDash(r.AGENCY_CONTACT_NAME__C)}</div>
                                                                <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                                <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.AGENCY_CONTACT_EMAIL__C)}</small></div>
                                                            </Col>
                                                        </Row>
                                                        <div className='mt-3 mb-1'><b><small>Corporation Contact</small></b></div>
                                                        <Row className='contactWrap'>
                                                            <Col className='initialWrap'>
                                                                <div className='initial disabled'>
                                                                    {r.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER__C)}</div>
                                                                <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                                <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='tmcRight' md={9}>
                                                        <Row className='pt-2 pb-2'>
                                                            <Col sm={2}>
                                                                <div><label>DRA Status</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.AGREEMENT_STATUS)}</span></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Data Status</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.DATA_STATUS)}</span></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">DRA Sent Date</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.dra_sent_date)}</span></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Approved Date</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.approved_date)}</span></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Ticketing Begin Date</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.rlse_dt)}</span></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Ticketing End Date</label></div>
                                                                <div className='type-caption-on-light'><span>{FDS.valueOrDash(r.rlse_end_dt)}</span></div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <div className='pt-2 pb-2'></div>
                                                        {statusTable({ ...r, tmcs: tmcs }, ri)}
                                                    </Col>
                                                </Row>
                                            )
                                        }) :
                                            <div>
                                                TMC records are not available for the selected corporation.
                                            </div>
                                    }
                                </Col>
                            </Row> : null
                        }

                        {isTMC === 2 ?
                            <>
                                <Row className='arcciCorpWrap tmc m-2 pt-2 pb-2 mb-3' style={{ background: "white", border: "none" }}>
                                    <Col>
                                        <Row>
                                            <Col style={{ maxWidth: "18px" }}>
                                                <span className={`dot xl ${getTMCStatusColor(corps.DATA_STATUS)} mt-3 mr-2`} title={corps.DATA_STATUS}></span>
                                            </Col>
                                            <Col>
                                                <h2 className='mb-1'>{FDS.valueOrDash(corps.ADC_AGENCY_NAME)}</h2>
                                                <div className='mb-1'>Agent Id: {FDS.valueOrDash(corps.tmc)}</div>
                                                <h6>TMC</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='contactWrap' md={3}>
                                        <Row>
                                            <Col className='initialWrap'>
                                                <div className='initial disabled'>{corps.AGENCY_CONTACT_NAME__C?.match(/\b(\w)/g)?.join('')}</div>
                                            </Col>
                                            <Col>
                                                <div>{FDS.valueOrDash(corps.AGENCY_CONTACT_NAME__C)}</div>
                                                <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(corps.AGENCY_CONTACT_EMAIL__C)}</small></div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="arcciCorpWrap tmc m-2" style={{ background: "white", border: "none" }}>
                                    <Col>
                                        <div className='pt-3'><h3>Associated Corporations</h3></div>
                                        <hr />
                                        <Row>
                                            <Col className='ms-0'>
                                                Records
                                                <b> {FDS.numberFormat((currentPage3 - 1) * itemPerPage3 + 1, 0, null)}</b> -
                                                <b> {FDS.numberFormat((currentPage3 - 1) * itemPerPage3 + itemPerPage3 < corps.corps?.length ? (currentPage3 - 1) * itemPerPage3 + itemPerPage3 : corps.corps?.length, 0, null)}</b> of
                                                <b> {FDS.numberFormat(corps.corps?.length, 0, null)}</b>
                                            </Col>
                                            <Col className='me-2 right'>
                                                <DynamicPagination currentPage={currentPage3} itemPerPage={itemPerPage3} totalItems={corps.corps?.length} onPageChange={(page: number) => setCurrentPage3(page)} />
                                            </Col>
                                        </Row>
                                        {
                                            [...(corps.corps || [])]?.slice((currentPage3 - 1) * itemPerPage3, (currentPage3 - 1) * itemPerPage3 + itemPerPage3)
                                                .map((r: any, ri: number) => {
                                                    return (
                                                        <>
                                                            <Row className='mb-3 pb-3' key={ri}>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='mt-3'>
                                                                                <div>
                                                                                    <span className={`dot ${getCorpStatusColor(r.corp_data_status)}`} title={r.corp_data_status}></span>
                                                                                    <b>{FDS.valueOrDash(r.ADC_CORP_NM)}</b>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='contactWrap' md={3}>
                                                                            <Row>
                                                                                <Col className='initialWrap'>
                                                                                    <div className='initial disabled'>{corps.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div>{FDS.valueOrDash(corps.COMPANY_TRAVEL_MANAGER__C)}</div>
                                                                                    <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                                                    <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(corps.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                    <div className='pt-3 pb-3'></div>
                                                                    {statusTable(corps.tmcs[ri], ri)}
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            <div className='pb-3 pt-3'></div>
                                                        </>
                                                    )
                                                })
                                        }
                                    </Col>
                                </Row>
                            </> : null
                        }
                    </> :
                    <Alert variant="info" className='mt-3'>No corporations are available for the selected filter criteria.</Alert>
            }
            {modalData?.data?.length > 0 &&
                <Modal
                    className="tcvModal"
                    show={modalData?.data?.length > 0}
                    onHide={() => setModalData({ data: [] })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Tour Code Validation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FDS.BarChart
                            id="TCV_Chart"
                            title=""
                            orientation="V"
                            sideBySide={true}
                            data={modalData?.data || []}
                            totalRecords={modalData?.data?.length || 0}
                            labels={{
                                x: "Timeframe",
                                y: "Ticket Count"
                            }}
                            keys={{
                                label: "ISSUE_MONTH",
                                values: ["TICKETS_SENT", "TICKETS_NOT_RECEIVED"],
                                legends: ["Tickets Sent", "Tickets Not Received"],
                                symbol: ""
                            }}
                            style={{
                                width: 600,
                                height: 500
                            }}
                            sortKey={""}
                        />
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}