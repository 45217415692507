import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Table, Breadcrumb, Alert, Button, Form, Badge } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { useExcelDownloder } from 'react-xls';
import { getUsersAction, postCustomerAction } from '../../store/actions';
import { DynamicPagination } from '../utils/pagination/DynamicPagination';
import { Const } from '../utils';
import { getOts, isExternalAdmin } from '../../okta/ls';
import "./Admin.scss"

declare const window: any;

export const CustomerDetails = (props: any) => {
    const ndc = "NDC"
    const aggt = "AGGREGATION"
    const include_fare = "FARE"
    const dispatch = useDispatch();
    const historyNav = useHistory();
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const agencyRef = referenceDataRef[window.ENV.references.agency]
    const customersRef = useSelector((state: any) => state.customersRef);
    const { ExcelDownloder, Type } = useExcelDownloder();
    const [customer, setCustomer]: any = useState({});
    const [userCount, setUserCount] = useState(0);
    const [users, setUsers]: any[] = useState([]);
    const params: any = useParams();
    const [id]: any = useState((params.id || customersRef.viewAs.org).toString());
    const itemPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)

    const [ndcIndicator, setNdcIndicator] = useState(false)
    const [aggtIndicator, setAggtIndicator] = useState(false)
    const [fareType, setFareType] = useState(false)
    const [agencySubscribers, setAgencySubscribers] = useState<[]>([])

    const [status]: any = useState({
        "Active": "Active",
        "Inactive": "Inactive"
    });

    // handle salesForce navigation
    const handleUser = () => {
        const url = customersRef.viewAs.role === Const.INTERNAL_ADMIN ? customer.sf_account_id ? window.ENV.sfBaseURL.replace("{acc_id}", customer.sf_account_id) : window.ENV.sfBaseURL.replace("r/Account/{acc_id}/view", "o/Account/list?filterName=__Recent") : window.ENV.eumBaseURL
        window.open(url, "_blank", "noreferrer")
    }

    const getDownloadData = useCallback(() => {
        return {
            "Customer": customersRef.customers.filter((cust: any) => !((cust.org === Const.ARC_ROLE || cust.name === Const.ARC_ROLE) && Const.CUSTOMER_ENTITIES.indexOf(cust.entity) > 0)).filter((c: any) => c.org === id).map((item: any) => {
                return {
                    "Org": item.org,
                    "Code": item.code,
                    "Customer Name": item.name,
                    "Contract Start Date": item.contract_start,
                    "Contract End Date": item.contract_end,
                    "Customer Type": item.entity,
                    "Maximum Allowed Users": item.max_allowed_users,
                    "Geography": Object.keys(item.geography)?.map((k: string) => (k.charAt(0).toUpperCase() + k.slice(1) + " (" + item.geography[k].join(", ") + ")")).join(", \u2029") || "-",
                    "Data Source": item.data_source?.join(", \u2029"),
                    "Data Content": item.data_content?.join(", \u2029")
                }
            }),
            "Users": customersRef.users
                .sort((a: any, b: any) => a.lastName > b.lastName ? 1 : -1)
                .map((item: any) => {
                    return {
                        "Name": item.lastName + " " + item.firstName,
                        "User ID": item.user_id,
                        // "Title": item.department,
                        // "Department": item.department,
                        "Role": Array.isArray(item.userRole) ? item?.userRole?.sort((a: string, b: string) => a >= b ? 1 : -1)?.join(", \u2029") : item.userRole,
                        "Email": item.email,
                        "Status": status[item.status]
                    }
                })
        }
    }, [id, status, customersRef]);

    const handleSearchChanged = (e: any) => {
        setCurrentPage(1);
        const filterTitle = customersRef?.users && [...customersRef.users].filter((msg: any) => ([msg.firstName, msg.lastName, msg.title, msg.department, msg.userRole?.toString(), msg.email].join(" "))?.toLowerCase()?.indexOf(e.target.value?.toLowerCase()) >= 0)
        setUsers(filterTitle)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({ type: "REPORT_IP", data: {} });
        dispatch({
            type: "GET_USERS",
            data: [],
        });
        dispatch(getUsersAction(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!isExternalAdmin() && customer.org && (customersRef.viewAs.org !== Const.ARC_ROLE && customersRef.viewAs.org !== customer.org)) {
            historyNav.push("/admin/customerinfo");
        }
    }, [customersRef.viewAs.org, customer, historyNav]);

    useEffect(() => {
        const customerRef = customersRef.customers.filter((cust: any) => !((cust.org === Const.ARC_ROLE || cust.name === Const.ARC_ROLE) && Const.CUSTOMER_ENTITIES.indexOf(cust.entity) > 0)).filter((c: any) => c.org === id)[0] || {}
        if (customerRef.data_content) {
            setNdcIndicator(customerRef.data_content.indexOf(ndc) >= 0)
            setAggtIndicator(customerRef.data_content.indexOf(aggt) >= 0)
            setFareType(customerRef.data_content.indexOf(include_fare) >= 0)
        }
        setCustomer(customerRef)

        // Filter ref obj based on subscription code
        if (agencyRef && customerRef) {
            const code = "code";
            const parent = "parent"
            const agencySubscribed = new Set(customerRef.subscribers).add(customerRef.org)
            const checkedCode = new Set();
            const filteredArray: any = [];

            for (const obj of agencyRef) {
                const value = obj[code];
                const parentCode = obj[parent]
                if ((agencySubscribed.has(value) || agencySubscribed.has(parentCode)) && !checkedCode.has(value)) {
                    filteredArray.push(obj);
                    checkedCode.add(value);
                }
            }
            setAgencySubscribers(filteredArray)
        }
        //To reset users state for excel report updates
        setUsers([]); // Donot remove this line
        setTimeout(() => {
            setUsers(customersRef.users.sort((a: any, b: any) => a.lastName > b.lastName ? 1 : -1));
            setUserCount(customersRef.users.length);
        }, 10);
    }, [customersRef.customers, customersRef.users, id, agencyRef])

    return (
        <FDS.FDSLevel mainTitle={FDS.valueOrDash(customer?.name)} subTitle={`${FDS.valueOrDash(customer?.entity)} - #${FDS.valueOrDash(customer?.org + (customer.code?.length === 2 ? ` - ${customer.code}` : ""))}`} type="three" name="adminDetailsPage">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="#/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item href="#/admin/customerinfo">Customer List</Breadcrumb.Item>
                    <Breadcrumb.Item active>Customer Details</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <FDS.FDSLevel.Centered>
                <Row>
                    <Col>
                        <label className='me-2'><b>Contract:</b></label>{customer?.contract_start} - {customer?.contract_end}
                    </Col>
                    <Col>
                        <label className='me-2'><b>Data Source:</b></label>{FDS.valueOrDash(customer?.data_source?.join(", "))}
                    </Col>
                    <Col>
                        <label className='me-2'><b>Subcription Info:</b></label>{FDS.valueOrDash(customer?.data_content?.filter((key: string) => key !== ndc && key !== aggt && key !== include_fare).join(", "))}
                    </Col>
                </Row>
                {/* All entities should be able to see this information */}
                <Row className='mt-3'>
                    <Col>
                        <Form>
                            <label className='me-2'><b>Include NDC Transactions</b></label>
                            <Form.Switch
                                className="custom-toggle"
                                name="ndcIndicator"
                                id="ndcSwitch"
                                label=""
                                aria-label='Include NDC Transactions'
                                checked={ndcIndicator}
                                disabled={customersRef.viewAs.role !== Const.INTERNAL_ADMIN}
                                onClick={() => {
                                    setNdcIndicator(!ndcIndicator)
                                    dispatch(postCustomerAction(customer.org, { data_content: ndc, user_id: getOts()?.idToken?.email.toLowerCase() || getOts()?.idToken?.claims?.preferred_username }))
                                }}
                            />
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <label className='me-2'><b>Aggregate Other Airlines</b></label>
                            <Form.Switch
                                className="custom-toggle"
                                name="aggtAirlines"
                                id="aggtAirlinesSwitch"
                                label=""
                                aria-label='Aggregate Other Airlines'
                                checked={aggtIndicator}
                                disabled={customersRef.viewAs.role !== Const.INTERNAL_ADMIN}
                                onClick={() => {
                                    setAggtIndicator(!aggtIndicator)
                                    dispatch(postCustomerAction(customer.org, { data_content: aggt, user_id: getOts()?.idToken?.email.toLowerCase() || getOts()?.idToken?.claims?.preferred_username }))
                                }}
                            />
                        </Form>
                    </Col>
                    <Col>
                        {customer.entity !== "AGENCY" && <Form>
                            <label className='me-2'><b>Include Adjusted Fare</b></label>
                            <Form.Switch
                                className="custom-toggle"
                                name="Include Adjusted Fare"
                                id="include_adjusted_fare"
                                label=""
                                aria-label='Include Adjusted Fare'
                                checked={fareType}
                                disabled={customersRef.viewAs.role !== Const.INTERNAL_ADMIN}
                                onClick={() => {
                                    setFareType(!fareType)
                                    dispatch(postCustomerAction(customer.org, { data_content: include_fare, user_id: getOts()?.idToken?.email.toLowerCase() || getOts()?.idToken?.claims?.preferred_username }))
                                }}
                            />
                        </Form>}
                    </Col>
                </Row>

            </FDS.FDSLevel.Centered>

            {customer?.geography && Object.keys(customer?.geography)?.length + customer?.modules?.length > 0 ?
                <FDS.FDSLevel.Centered>
                    <Row>
                        {Object.keys(customer?.geography)?.sort((a: any, b: any) => a < b ? 1 : -1).map((k: string, ki: number) => {
                            return (
                                <Col key={ki}>
                                    <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        <Col>
                                            <label><b>{k}</b></label>
                                        </Col>
                                        {customer.geography[k].length > 10 ?
                                            <Col className='right me-4'>
                                                <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                    ev.preventDefault();
                                                    ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                                }}>
                                                    <small>View More</small>
                                                    <small className='hidable'>View Less</small>
                                                </a>
                                            </Col> : null
                                        }
                                    </Row>
                                    <div>
                                        {customer.geography[k].map((geog: string, gi: number) => {
                                            return <Badge key={gi} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="primary">{geog}</Badge>
                                        })}
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    {
                        customer?.modules?.length > 0 ?
                            <Row className='pt-1'>
                                <Col>
                                    <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        <label><b>Modules</b></label>
                                    </div>
                                    <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                        {customer?.modules?.length > 10 ?
                                            <Col className='right me-4'>
                                                <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                    ev.preventDefault();
                                                    ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                                }}>
                                                    <small>View More</small>
                                                    <small className='hidable'>View Less</small>
                                                </a>
                                            </Col> : null
                                        }
                                    </Row>
                                    <div>
                                        {customer?.modules?.map((mod: string, gi: number) => {
                                            return <Badge key={gi} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="info">{mod}</Badge>
                                        })}
                                    </div>
                                </Col>
                            </Row> :
                            null
                    }
                    {agencySubscribers.length > 0 ?
                        <Row className='pt-3'>
                            <Col>
                                <Row style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                    <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold", width: "24rem" }}>
                                        <label><b>Agencies Subscribed</b></label>
                                    </div>
                                    {agencySubscribers?.length > 10 ?
                                        <Col className='right me-4'>
                                            <a href="/#" style={{ border: 'none' }} onClick={(ev: any) => {
                                                ev.preventDefault();
                                                ev.target.parentElement.parentElement.parentElement.classList.toggle("show")
                                            }}>
                                                <small>View More</small>
                                                <small className='hidable'>View Less</small>
                                            </a>
                                        </Col> : null
                                    }
                                </Row>
                                <div>
                                    {agencySubscribers?.map((mod: any, gi: number) => {
                                        return <Badge key={gi} className={`p-1 ${gi >= 10 ? 'hidable' : ''}`} bg="secondary">{`${mod.name || mod.lname} (${mod.code})`}</Badge>
                                    })}
                                </div>
                            </Col>
                        </Row> : null
                    }

                </FDS.FDSLevel.Centered> :
                <div></div>
            }
            {userCount > 0 ?
                <FDS.FDSLevel.Centered>
                    <Row className="fds-pl-column p-0 mb-0">
                        <Col className={`ADMIN pt-3 pb-3`} style={{ minHeight: '450px', overflow: "auto" }}>
                            <div>
                                <Row className='pb-3'>
                                    <Col>
                                        <Form.Control id="filterId" aria-label='Search Customers' name="filterId" type="text" autoComplete="off" onChange={(ev) => handleSearchChanged(ev)} placeholder='Search Users' style={{ width: "100%" }} />
                                        <Form.Text>Search for user by name, title, department, role, or e-mail</Form.Text>
                                    </Col>
                                    <Col className='right'>
                                        {users.length > 0 ?
                                            <ExcelDownloder
                                                className="btn btn-secondary mt-4"
                                                data={getDownloadData()}
                                                filename={"UsersList - " + customer.name}
                                                type={Type.Button}
                                            >
                                                Export Data
                                            </ExcelDownloder> : null
                                        }
                                        <Button className='ms-3 mt-4' variant='primary' onClick={() => handleUser()}>Manage Users</Button>
                                    </Col>
                                </Row>
                                {users.length > 0 ?
                                    <div>
                                        <Table className='fds-table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>User ID</th>
                                                    {/* <th>Title</th>
                                                    <th>Department</th> */}
                                                    <th>Role</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(users.slice((currentPage - 1) * itemPerPage, (currentPage - 1) * itemPerPage + itemPerPage) || [])?.map((user: any, i: number) => {
                                                    return (<tr key={i}>
                                                        <td className='capitalize'>{user.lastName} {user.firstName}</td>
                                                        <td>{/*user.email ? user.email : */user.user_id}</td>
                                                        {Array.isArray(user.userRole) ? <td>{user.userRole?.sort((a: string, b: string) => a >= b ? 1 : -1).map((role: any) => <div key={role}>{role}</div>)}</td> : <td>{<div key={i}>{user.userRole}</div>}</td>}
                                                        <td>{user.email}</td>
                                                        <td className={user.status === "Inactive" ? "dangerTxt" : ""}>{status[user.status]}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>
                                        {users.length > itemPerPage ?
                                            <div style={{ textAlign: "center" }}>
                                                <DynamicPagination itemPerPage={itemPerPage} totalItems={users.length} onPageChange={(page: number) => setCurrentPage(page)} />
                                            </div> : null
                                        }
                                    </div> :
                                    <Alert data-testid="table-main-no-data" className="mt-3" variant="primary">
                                        <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                        <div className="body">There are no records for this search criteria.</div>
                                    </Alert>
                                }
                            </div>
                        </Col>
                    </Row>
                </FDS.FDSLevel.Centered> :
                <Container className='mt-3 pt-3'>
                    <Alert data-testid="table-main-no-data" variant="primary">
                        <div className="icon"><i className="fds-glyphs-info2"></i></div>
                        <div className="body">
                            There are currently no users available for this customer. Add a new user <a href="/#" onClick={(ev: any) => { ev.preventDefault(); handleUser(); return false; }}><b>here</b></a>.
                        </div>
                    </Alert>
                </Container>
            }
            <div className='p-3'></div>
        </FDS.FDSLevel>
    );
}