import axios from "axios";
import { getOts } from '../../../okta/ls';
import { capitalize, Const, processResponse } from '../../../components/utils'
import store from "../..";

declare const window: any;
export const getSavedGroupingsAction = () => {
  return async (dispatch: any) => {
    const viewAs = store.getState().customersRef.viewAs;
    //dispatch({ type: "LOADING", data: "Loading Groups Data" });
    const groupsPromise = axios.get(window.ENV.groupingsDomain + (viewAs.org !== Const.ARC_ROLE && viewAs.org?.length > 0 ? ("?org=" + viewAs.org) : ""), {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bearer"),
        "Content-Type": "application/json"
      }
    });

    const alianceAirportGroupsPromise = axios.get(window.ENV.alliance.airport, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bearer"),
        "Content-Type": "application/json"
      }
    });

    const alianceAirlineGroupsPromise = axios.get(window.ENV.alliance.airline, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bearer"),
        "Content-Type": "application/json"
      }
    });

    await Promise.all([groupsPromise, alianceAirlineGroupsPromise, alianceAirportGroupsPromise]).then((responses: any) => {
      let data: any = [];
      for (let i = 0; i < responses.length; i++) {
        data[i] = processResponse(responses[i]);
      }

      const org = typeof data[0]?.org === "string" ? data[0]?.org : data[0]?.org?.map((o: any) => o !== Const.ARC_ROLE ? capitalize(o) : o).join(",")

      console.log("ORG", org)
      const r0 = data[0]?.result;
      const r1 = data[1]?.results?.map((item: any) => {
        return {
          "name": item.alliance,
          "values": [...item.carriers],
          "code": item.carriers.toString(),
          "priority": "0",
          "shared": "1",
          "type": "airline",
          "user_id": (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase(),
        };
      });

      // Filter Data based on user access and config
      // const customerObj = store.getState().customersRef.customer;

      // To test
      // customerObj.geography = {
      //   city: ["DFW", 'ABI'],
      //   airport: ['DFW', 'DAL', 'JDB', "DYS", 'JFK'],
      // };

      // Filter System Groupings based on user access and config
      // (customerObj?.geography && data.length > 0 ? Object.keys(customerObj.geography) : []).forEach((geographyKey: any) => {
      //   if (Object.keys(data[2].results[0]).indexOf(geographyKey + "_code") >= 0) {
      //     const geog = customerObj.geography[geographyKey];
      //     if (geog.length > 0) {
      //       if (geographyKey === 'airport') {
      //         const airpotsRefFiltered: any = []
      //         data[2].results.forEach((item: any) => {
      //           if (item.airport_code.some((code: any) => geog.indexOf(code) >= 0))
      //             airpotsRefFiltered.push({
      //               airport_code: item.airport_code.filter((code: any) => geog.indexOf(code) >= 0),
      //               city_code: item.city_code,
      //               description: item.description
      //             })
      //         })
      //         data[2].results = airpotsRefFiltered
      //       }
      //     }
      //   }
      // })
      //END: 
      const r2 = data[2]?.results?.map((item: any) => {
        return {
          "name": item.description,
          "values": [...item.airport_code],
          "code": item.airport_code.toString(),
          "priority": "0",
          "shared": "1",
          "type": "airport",
          "user_id": (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase(),
        };
      });

      const finalResult = [
        ...r0?.sort((a: any, b: any) => a.name > b.name ? 1 : -1),
        ...r1?.sort((a: any, b: any) => a.name < b.name ? 1 : -1),
        ...r2?.sort((a: any, b: any) => a.name < b.name ? 1 : -1)
      ];
      dispatch({
        type: "GROUPINGS_ORG",
        data: org ? org : ""
      });

      dispatch({
        type: "GROUPINGS_DATA",
        data: [...finalResult],
      });

      const groupFinalMerge = finalResult.filter((d: any) =>
        // system groups
        d.shared === "1" ||
        // private groups
        (d.user_id?.toLowerCase() === (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase()) ||
        // shared with org group
        (d.shared === "2" && d.org === (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org)) ||
        // shared with viewAs group
        (d.shared === "2" && d.org === viewAs?.org)
      )

      dispatch({
        type: "GROUP_MERGE",
        data: [...groupFinalMerge],
      });

      //dispatch({ type: "LOADING", data: "" });
    }).catch((err) => {
      console.log("ERROR:", err);
      dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
      dispatch({ type: "LOADING", data: "" });
    });
  };
};


export const setSavedGroupingsAction = (body: any[], req: string) => {
  return async (dispatch: any) => {
    const axiosRequest = (request: string, body: any[]) => {
      if (request === "post") {
        dispatch({ type: "LOADING", data: `Saving ${body[0].name} Group` })
        return axios.post(window.ENV.groupingsDomain, body, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("bearer"),
            "Content-Type": "application/json",
          },
        })
      }
      if (request === "put") {
        dispatch({ type: "LOADING", data: `Updating ${body[0].name} Group` })
        return axios.put(window.ENV.groupingsDomain, body, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("bearer"),
            "Content-Type": "application/json",
          },
        })
      }
    }
    console.log(req, body)
    axiosRequest(req, body)?.then((response) => {
      console.log("RES:", response)
      dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Group (${body[0].name}) has been ${req === "put" && body[0]?.shared === "2" ? "shared" : "saved"} succesfully` } });
      dispatch(getSavedGroupingsAction());
      dispatch({ type: "LOADING", data: "" })
    }).catch(err => {
      console.log("ERROR:", err);
      dispatch({ type: "LOADING", data: "" })
      dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
    });
  };
};

export const deleteSavedGroupingsAction = (Id: string, name: string) => {
  return (dispatch: any) => {
    dispatch({ type: "LOADING", data: `Deleting ${name} Group` });
    axios.delete(window.ENV.groupingsDomain, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bearer"),
        "Content-Type": "application/json",
      },
      params: {
        uid: Id,
      },
    }).then((response) => {
      console.log("RES:", response);
      dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Group (${name}) removed succesfully` } });
      dispatch(getSavedGroupingsAction());
      dispatch({ type: "LOADING", data: "" });
    }).catch((err) => {
      console.log("ERROR:", err);
      dispatch({ type: "LOADING", data: "" })
      dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
    });
  };
};
