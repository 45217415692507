declare const window: any;

export const referenceLoadedRef = (state = { status: false }, action: any) => {
    switch (action.type) {
        case "DONE":
            return { status: true };
        default:
            return state;
    }
}

export const templatesLoadedRef = (state: any = { status: false }, action: any) => {
    switch (action.type) {
        case "TEMPLATES_DONE":
            return { status: action.data.status };
        default:
            return state;
    }
}

export const referenceDataRef = (state: any = {}, action: any) => {
    let prev: any;
    switch (action.type) {
        case "REFERENCE_DATA":
            prev = { ...state };
            if (!prev[action.field]) {
                prev[action.field] = [];
            }

            let d = [...action.data];

            prev[action.field] = [...prev[action.field], ...d];
            return prev;
        case "CLEAR_AGENCY_REFERENCE_DATA":
            prev = { ...state };
            prev[window.ENV.references.agency] = [];
            return prev;
        case "CLEAR_REFERENCE_DATA":
            return {};
        case "DISTINCT_REFERENCE_DATA":
            const tmpCode: any = {};
            state[window.ENV.references.agency].forEach((item: any) => {
                tmpCode[item.code] = { ...item };
            });

            const lst: any = [];
            Object.keys(tmpCode).forEach((iKey: any) => {
                lst.push(tmpCode[iKey])
            })

            state[window.ENV.references.agency] = lst;

            return state;
        case "GROUP_MERGE":
            prev = { ...state };
            Object.keys(state).forEach((rKey: any) => {
                const apap: any[] = [];
                prev[rKey] = prev[rKey].filter((obj: any) => !obj.top);
                action.data
                    .forEach((group: any) => {
                        if (rKey.indexOf(group.type) > 0) {
                            group['code'] = group.values.toString();
                            group['description'] = group.name;
                            group['top'] = true;
                            prev[rKey].unshift({ ...group });
                        }
                        if (group.type === "apap") {
                            group['code'] = group.values.toString();
                            group['description'] = group.name;
                            group['top'] = true;

                            apap.push({ ...group });
                        }
                    });

                // Save only Groups
                ["airline", "agency", "airport", "region"].forEach((key: string) => {
                    if (rKey.indexOf(key) > 0) {
                        localStorage.setItem("REF:" + rKey, JSON.stringify(prev[rKey].filter((o: any) => o.top)));
                    }
                })

                // Save Markets: apap
                localStorage.setItem("REF:apap", JSON.stringify(apap));

                // Save actual data since no groups are present for these criteria
                ["dtperiods", "country", "city", "corporate", "agent"].forEach((key: string) => {
                    if (rKey.indexOf(key) > 0) {
                        localStorage.setItem("REF:" + rKey, JSON.stringify(prev[rKey]));
                    }
                })

            });
            return prev;
        default:
            return state;
    }
};
