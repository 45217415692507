import axios from 'axios';
import store from "../..";
import { Const, processResponse } from '../../../components/utils';

declare const window: any;

export const getAllSavedTemplatesAction = (noClear?: boolean) => {
    return (dispatch: any) => {
        const viewAs = store.getState().customersRef.viewAs;
        //dispatch({ type: "LOADING", data: "Loading Template Data" });
        axios.get(window.ENV.templatesDomain + "template" + (viewAs.org && viewAs.org !== Const.ARC_ROLE ? "?org=" + viewAs.org : ""), {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const data = processResponse(response);
            dispatch({
                type: 'ALL_SAVED_TEMPLATES',
                data: data?.template
            });
        }).catch(err => {
            console.log("ERROR:", err);
            //dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};

export const getTemplateAction = (template_id: string, hObj: any = null) => {
    return (dispatch: any) => {
        //dispatch({ type: "LOADING", data: "Loading Template Data" });
        axios.get(`${window.ENV.templatesDomain}template?Id=${template_id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            if (data?.totalRecords <= 0) {
                dispatch({ type: "RERUN_TEMPLATE", data: hObj });
            } else {
                dispatch({ type: "RERUN_TEMPLATE", data: data?.template[0]?.request });
            }

        }).catch(err => {
            console.log("ERROR:", err);
            //dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};

export const getSavedTemplatesAction = (report_type: string) => {
    return (dispatch: any) => {
        const viewAs = store.getState().customersRef.viewAs;
        //dispatch({ type: "LOADING", data: "Loading Template Data" });
        axios.get(window.ENV.templatesDomain + "template?report_type=" + report_type + (viewAs.org && viewAs.org !== Const.ARC_ROLE ? "&org=" + viewAs.org : ""), {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const data = processResponse(response);
            dispatch({
                type: 'SAVED_TEMPLATES',
                data: data?.template
            });
            dispatch({ type: 'TEMPLATES_DONE', data: { status: true } });
        }).catch(err => {
            console.log("ERROR:", err);
            //dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};

export const setSavedTemplatesAction = (body: any, type: string) => {
    return (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Template saving" });
        axios.post(window.ENV.templatesDomain + "template?req_type=CREATE", { ...body }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const data = processResponse(response);
            dispatch(getSavedTemplatesAction(type.toUpperCase()));
            dispatch(getAllSavedTemplatesAction());
            dispatch({
                type: 'SAVED_TEMPLATES_SUCCESS',
                data: data
            });
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Template (${body.template?.template_name}) has been saved succesfully` } });
        }).catch(err => {
            console.log("ERROR:", err);
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
}

export const removeSavedTemplatesAction = (body: any) => {
    return (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Deleting Template" });
        axios.delete(window.ENV.templatesDomain + "template?id=" + body.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            },
            data: body
        }).then((response) => {
            console.log("RES:", response);
            dispatch(getAllSavedTemplatesAction());
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Template (${body.template_name}) deleted succesfully` } });
        }).catch(err => {
            console.log("ERROR:", err);
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
}

export const updateSavedTemplatesAction = (body: any, tempName: string) => {
    return (dispatch: any) => {
        dispatch({ type: "LOADING", data: "Updating Template " });
        axios.put(window.ENV.templatesDomain + "template", body, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log("RES:", response);
            dispatch(getAllSavedTemplatesAction());
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Template (${tempName}) ${body[0]?.request?.schedule?.status} succesfully` } });
        }).catch(err => {
            console.log("ERROR:", err);
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
};
