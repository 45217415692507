import * as FDS from "@arctravel/react-fds/lib";

declare const window: any;

const GLOBALDATA = "GLOBAL"
const ticketSubscription = "GLOBAL TICKET"
const segmentSubscription = "SEGMENT"
const fareSubscription = "FARE"
const airlineEntity = "AIRLINE"
const agencyEntity = "AGENCY"
const financialEntity = "THIRDPARTY-FINANCIAL"
const nonFinancialEntity = "THIRDPARTY-NON-FINANCIAL"
const ndcSubscription = "NDC"
const aggt = "AGGREGATION"

const fields = {
    // Data Metrics
    "metrics_parent": [
        { label: "Count", key: "count" },
        { label: "Segment Passengers", key: "seg_pax", showFor: { subscription: segmentSubscription } },
        { label: "Trip Passengers", key: "trip_pax" },
        { label: "Trip Passengers (No Exchange)", key: "trip_no_exchange" },
        { label: "Passenger Share", key: "pax_share" },
        { label: "Passenger Share (No Exchange)", key: "pax_share_no_exchange" },
        { label: "Blended Fare Breakdown", key: "blended_fare_breakdown" },
        { label: "RPM (pax-Miles)", key: "rpm" },
        { label: "Distance (Miles)", key: "distance" },
    ],
    "Revenue Fields": [
        { label: "Blended Fare (Commissionable)", key: "blended_fare" },
        { label: "Actual Fare", key: "actual_fare" },
        { label: "Industry Fare", key: "industry_fare" },
        { label: "Blended Revenue (Commissionable)", key: "blended_revenue" },
        { label: "Revenue Share", key: "revenue_share" },
        { label: "Actual Revenue Share", key: "actual_share" },
        { label: "Industry Revenue Share", key: "industry_share" },
        { label: "Blended Yield", key: "blended_yield" },
        { label: "Actual Yield", key: "actual_yield" },
        { label: "Industry Yield", key: "industry_yield" },
        { label: "Blended Segment Revenue", key: "seg_blended_revenue", showFor: { subscription: segmentSubscription } },
        { label: "Blended Segment Fare", key: "seg_blended_fare", showFor: { subscription: segmentSubscription } },
        { label: "Segment Revenue Share", key: "seg_revenue_share", showFor: { subscription: segmentSubscription } }
    ],
    "Payment Amount Fields": [
        { label: "Blended Average Payment Amount", key: "avg_blended_pay" },
        { label: "Average Actual Payment Amount", key: "avg_actual_pay" },
        { label: "Average Industry Payment Amount", key: "avg_industry_pay" },
        { label: "Blended Payment Amount", key: "blended_pay" },
        { label: "Actual Payment Amount", key: "actual_pay" },
        { label: "Industry Payment Amount", key: "industry_pay" },
        { label: "Blended payment Amount Yield", key: "blended_pay_yield" },
        { label: "Actual Payment Amount Yield", key: "actual_pay_yield" },
        { label: "Industry Payment Amount Yield", key: "industry_pay_yield" }
    ],
    "Adjusted Fares": [
        { label: "Adjusted Fare", key: "adjusted_fare", showFor: { entity: [airlineEntity] } },
        { label: "Adjusted Revenue", key: "adjusted_revenue" },
        { label: "Adjusted Yield", key: "adjusted_yield" },
        { label: "Adjusted Average Payment Amount", key: "avg_adjusted_pay", showFor: { subscription: ticketSubscription } },
        { label: "Adjusted Payment Amount", key: "adjusted_pay", showFor: { subscription: ticketSubscription } },
        { label: "Adjusted Payment Amount Yield", key: "adjusted_pay_yield", showFor: { subscription: ticketSubscription } }
    ],
    "Net Revenue": [
        { label: "Total Net Revenue", key: "net_revenue" },
        { label: "Average Net Revenue", key: "avg_net_revenue" }
    ],
    "Commission": [
        { label: "Total Commission", key: "commission" },
        { label: "Average Commission", key: "avg_commission" }
    ],
    "Supplementary Amount": [
        { label: "Total Supplementary Amount", key: "supp_amount" },
        { label: "Average Supplementary Amount", key: "avg_supp_amount" }
    ],
    "Taxes and Fees": [
        { label: "Total Taxes and Fees", key: "taxes_fees" },
        { label: "Average Taxes and Fees", key: "avg_taxes_fees" }
    ],
    // End: Data Metrics

    // General
    "general_parent": [
        { label: 'Trip Purpose', key: 'trip_purpose' },
        { label: 'NDC Indicator', key: 'ndc', showFor: { subscription: ndcSubscription } },
        { label: 'Country of Sale', key: 'pos_country' },
        { label: 'Data Source', key: 'source' }
    ],
    "Purchase Period": [
        { label: "Purchase Date", key: "purchase_date" },
        { label: "Purchase Month", key: "purchase_month" },
        { label: "Purchase Calendar Month", key: "purchase_calendar_month" },
        { label: "Purchase Quarter", key: "purchase_quarter" },
        { label: "Purchase Year", key: "purchase_year" }
    ],
    "Travel Period": [
        { label: 'Travel Date', key: 'travel_date' },
        { label: 'Travel Day of Week', key: 'travel_date_week' },
        { label: "Travel Month", key: "travel_month" },
        { label: "Travel Quarter", key: "travel_quarter" },
        { label: "Travel Year", key: "travel_year" }
    ],
    // END: General

    // Segment
    "segment_parent": [
        { label: 'Marketing Airline', key: 'seg_mkt_al', showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: 'Operating Airline', key: 'seg_opt_al', showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: 'Fare Basis', key: 'seg_fare' },
        { label: 'Coupon Number', key: 'coupon_number' },
        { label: 'Class of Service', key: 'segment_cabin_class' },
        { label: 'Segment RBKD', key: 'segment_rbkd' },
        { label: 'Marketing Flight Number', key: 'seg_mkt_al_num', showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: 'Operating Flight Number', key: 'seg_opt_al_num', showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: 'Stopover Flag', key: 'stopover' },
        { label: 'Trip O&D Market', key: 'trip_od', showFor: { entity: [airlineEntity, agencyEntity] } }
    ],
    "Segment Origin": [
        { label: "Segment Origin Code", key: "o_segment", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Segment Origin City", key: "o_segment_city_cd", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Segment Origin Country", key: "o_segment_country_cd" },
        { label: "Segment Origin Continent", key: "o_segment_continent" }
    ],
    "Segment Destination": [
        { label: "Segment Destination Code", key: "d_segment", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Segment Destination City", key: "d_segment_city_cd", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Segment Destination Country", key: "d_segment_country_cd" },
        { label: "Segment Destination Continent", key: "d_segment_continent" }
    ],
    "Segment Through Airport": [
        { label: "Segment Through Code", key: "thru_segment" },
        { label: "Segment Through City", key: "thru_segment_city_cd" },
        { label: "Segment Through Country", key: "thru_segment_country_cd" },
        { label: "Segment Through Continent", key: "thru_segment_continent" }
    ],
    // END: Segment

    // Trip
    "trip_parent": [
        { label: "Trip Dominant Class of Service", key: "trip_cabin_class" },
        { label: "Trip Dominant RBKD", key: "trip_rbkd" },
        { label: "Trip Dominant Marketing Airline", key: "dom_mkt_al", showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: "Trip Marketing Airlines", key: "mkt_al", showFor: { subscription: segmentSubscription } },
        { label: "Trip Dominant Operating Airline", key: "dom_opt_al", showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: "Trip Operating Airlines", key: "opt_al", showFor: { subscription: segmentSubscription } },
        { label: "Trip Market Pair", key: "markets", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Connect Points", key: "connect_points", showFor: { subscription: segmentSubscription } },
        { label: "Days Sold Prior to Travel", key: "days_sold_prior" },
        { label: "Nights Stayed", key: "nights_stayed" }
    ],
    "Trip Origin": [
        { label: "Trip Origin Code", key: "org", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Trip Origin City", key: "o_city_cd", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Trip Origin Country", key: "o_country_cd" },
        { label: "Trip Origin Continent", key: "o_continent" }
    ],
    "Trip Destination": [
        { label: "Trip Destination Code", key: "dst", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Trip Destination City", key: "d_city_cd", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Trip Destination Country", key: "d_country_cd" },
        { label: "Trip Destination Continent", key: "d_continent" }
    ],
    // END: Trip

    // Ticket
    "ticket_parent": [
        { label: "Ticket Type", key: "ticket_type" },
        // { label: "GDS", key: "gds", showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: "Point of Origin Airline", key: "poo_mkt_al", showFor: { entity: [airlineEntity, agencyEntity] } },
        { label: "All Marketing Airlines on Ticket", key: "tkt_mkt_als", showFor: { entity: [] } },
        { label: "All Operating Airlines on Ticket", key: "tkt_opt_als", showFor: { entity: [] } },
        { label: "All Trip O&Ds on Ticket", key: "tkt_ods", showFor: { subscription: ticketSubscription } },
        { label: "All Coupons on Ticket", key: "tkt_coupons", showFor: { subscription: ticketSubscription } },
        { label: "E-Ticket Flag", key: "e_ticket", showFor: { subscription: ticketSubscription } },
        { label: "Domestic/International Flag", key: "international_indicator", showFor: { subscription: ticketSubscription } },
        { label: "Form of Payment Currency", key: "payment_currency", showFor: { subscription: ticketSubscription } },
        { label: "Form of Payment Exchange", key: "payment_exchange", showFor: { subscription: ticketSubscription } },
        // { label: "Ticket/Document Number", key: "ticket", showFor: { subscription: ticketSubscription } },
        { label: "Distribution Channel", key: "distribution_channel", showFor: { subscription: ticketSubscription } },
        { label: "Ticketing Airline Code", key: "tkt_al", showFor: { entity: [airlineEntity, agencyEntity] } }
    ],
    "Travel Agency": [
        { label: "Agency ID", key: "agency" },
        { label: "Agency Home Office ID", key: "hol" },
        { label: "Agency Name", key: "agency_legal_name" },
        { label: "Agency Address", key: "agency_street_address" },
        { label: "Agency City", key: "agency_city" },
        { label: "Agency Postal Code", key: "agency_postal" },
        { label: "Agency State", key: "agency_state" },
        { label: "Agency Country", key: "agency_country" },
        { label: "Agency Continent", key: "agency_continent" }
    ],
    // "Ticketing Airline": [{ label: "Code", key: "tkt_al" }],
    "Point of Origin Airport": [
        { label: "Point of Origin Code", key: "poo_org", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Point of Origin City", key: "poo_city_cd", showFor: { entity: [airlineEntity, agencyEntity, nonFinancialEntity] } },
        { label: "Point of Origin Country", key: "poo_country_cd" },
        { label: "Point of Origin Continent", key: "poo_continent" }
    ],
    "Details for Exchanges, Refunds, and other Transactions": [
        { label: "Transaction Code", key: "transaction" },
        { label: "Original Issue Information", key: "original_issue", showFor: { subscription: ticketSubscription } },
        { label: "Original Primary Ticket Key", key: "primary_ticket", showFor: { subscription: ticketSubscription } },
        { label: "Exchanged/Refunded Coupon Numbers", key: "exchanged_coupons", showFor: { subscription: ticketSubscription } }
    ],
    "Others": [
        { label: "PNR Code", key: "pnr" },
        { label: "Tour Code", key: "tour_code" }
    ],
    // END: Ticket

    // Group
    "group_parent": [
        { label: "Agency Group", key: "agency_code_number_group", showFor: { entity: [airlineEntity] } },
        { label: "Airline Group", key: "airline_group", disableFor: { subscription: aggt } }
    ],
    // END: Group
};

// Data Metrics
const dataProprietaryFields = [
    {
        label: 'Payment Amount Fields',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Payment Amount Fields"].map((f: any) => f.key)
        ].toString(),
        className: "proprietaryfields",
        children: [
            ...fields["Payment Amount Fields"]
        ].map((child: any) => ({ ...child, className: "proprietaryfields" }))
    },
    {
        label: 'Taxes and Fees',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Taxes and Fees"].map((f: any) => f.key)
        ].toString(),
        className: "proprietaryfields",
        children: [
            ...fields["Taxes and Fees"]
        ].map((child: any) => ({ ...child, className: "proprietaryfields" }))
    },
    {
        label: 'Commission',
        showFor: { subscription: ticketSubscription, entity: [agencyEntity] },
        key: [
            fields["Commission"].map((f: any) => f.key)
        ].toString(),
        className: "proprietaryfields",
        children: [
            ...fields["Commission"]
        ].map((child: any) => ({ ...child, className: "proprietaryfields" }))
    },
    {
        label: 'Supplementary Amount',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Supplementary Amount"].map((f: any) => f.key)
        ].toString(),
        className: "proprietaryfields",
        children: [
            ...fields["Supplementary Amount"]
        ].map((child: any) => ({ ...child, className: "proprietaryfields" }))
    },
    {
        label: 'Net Revenue',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Net Revenue"].map((f: any) => f.key)
        ].toString(),
        className: "proprietaryfields",
        children: [
            ...fields["Net Revenue"]
        ].map((child: any) => ({ ...child, className: "proprietaryfields" }))
    }
]
// Columns Field
const proprietaryFields = {
    label: "Proprietary Fields",
    key: "seg_fare,gds,original_issue,pnr,tour_code ",
    className: "proprietaryfields",
    showFor: { entity: [airlineEntity, agencyEntity] },
    proprietary: [airlineEntity, agencyEntity],
    children: [
        { label: 'Fare Basis', key: 'seg_fare', parent: "Segment Fields", className: "proprietaryfields", showFor: { subscription: segmentSubscription } },
        { label: "GDS", key: "gds", parent: "Ticket Fields", className: "proprietaryfields", showFor: { subscription: ticketSubscription, entity: [agencyEntity] }, },
        { label: "Ticket/Document Number", key: "ticket", parent: "Ticket Fields", className: "proprietaryfields", showFor: { subscription: ticketSubscription } },
        { label: "Original Issue Information", key: "original_issue", parent: "Ticket Fields", grandParent: "Details for Exchanges, Refunds, and other Transactions", className: "proprietaryfields", showFor: { subscription: ticketSubscription, entity: [agencyEntity] } },
        { label: 'Others', key: [fields["Others"].map((f: any) => f.key)].toString(), parent: "Ticket Fields", children: [...fields["Others"]].map((child: any) => ({ ...child, className: "proprietaryfields" })), className: "proprietaryfields", showFor: { subscription: ticketSubscription, entity: [agencyEntity] } }
    ]
}

const dataMetricFields = [
    ...fields.metrics_parent,
    {
        label: 'Value Fields (for all tickets)',
        key: [
            fields["Revenue Fields"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Revenue Fields"]
        ]
    }, {
        label: 'Payment Amount Fields',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Payment Amount Fields"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Payment Amount Fields"]
        ]
    }, {
        label: 'Adjusted',
        showFor: { subscription: fareSubscription },
        key: [
            fields["Adjusted Fares"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Adjusted Fares"]
        ]
    }, {
        label: 'Net Revenue',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Net Revenue"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Net Revenue"]
        ]
    }, {
        label: 'Commission',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Commission"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Commission"]
        ]
    }, {
        label: 'Supplementary Amount',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Supplementary Amount"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Supplementary Amount"]
        ]
    }, {
        label: 'Taxes and Fees',
        showFor: { subscription: ticketSubscription },
        key: [
            fields["Taxes and Fees"].map((f: any) => f.key)
        ].toString(),
        children: [
            ...fields["Taxes and Fees"]
        ]
    }, {
        label: "Proprietary Fields",
        key: [...dataProprietaryFields].map((f: any) => f.key).toString(),
        className: "proprietaryfields",
        showFor: { entity: [airlineEntity, agencyEntity] },
        proprietary: [airlineEntity, agencyEntity],
        children: [
            ...dataProprietaryFields
        ]
    }
];

const generalRCs = {
    label: 'General Fields',
    key: [
        fields["Purchase Period"].map((f: any) => f.key),
        fields["Travel Period"].map((f: any) => f.key),
        fields.general_parent.map((f: any) => f.key)
    ].toString(),
    children: [
        {
            label: 'Purchase Period',
            key: fields["Purchase Period"].map((f: any) => f.key).toString(),
            children: [...fields["Purchase Period"]],
        }, {
            label: 'Travel Period',
            key: fields["Travel Period"].map((f: any) => f.key).toString(),
            children: [...fields["Travel Period"]],
        },
        ...fields.general_parent
    ]
}

const segmentRCs = {
    label: 'Segment Fields',
    showFor: { subscription: segmentSubscription },
    key: [
        fields["Segment Origin"].map((f: any) => f.key),
        fields["Segment Destination"].map((f: any) => f.key),
        fields.segment_parent.map((f: any) => f.key)
    ].toString(),
    children: [
        ...fields.segment_parent,
        {
            label: 'Segment Origin',
            key: fields["Segment Origin"].map((f: any) => f.key).toString(),
            children: [...fields["Segment Origin"]],
        }, {
            label: 'Segment Destination',
            key: fields["Segment Destination"].map((f: any) => f.key).toString(),
            children: [...fields["Segment Destination"]],
        }
    ]
}

const tripRCs = {
    label: 'Trip Fields',
    key: [
        fields["Trip Origin"].map((f: any) => f.key),
        fields["Trip Destination"].map((f: any) => f.key),
        fields.trip_parent.map((f: any) => f.key)
    ].toString(),
    children: [
        ...fields.trip_parent,
        {
            label: 'Trip Origin',
            key: fields["Trip Origin"].map((f: any) => f.key).toString(),
            children: [...fields["Trip Origin"]],
        }, {
            label: 'Trip Destination',
            key: fields["Trip Destination"].map((f: any) => f.key).toString(),
            children: [...fields["Trip Destination"]],
        }
    ]
}

const ticketRCs = {
    label: 'Ticket Fields',
    key: [
        fields["Travel Agency"].map((f: any) => f.key),
        // fields["Ticketing Airline"].map((f: any) => f.key),
        fields["Point of Origin Airport"].map((f: any) => f.key),
        fields["Details for Exchanges, Refunds, and other Transactions"].map((f: any) => f.key),
        fields["Others"].map((f: any) => f.key),
        fields.ticket_parent.map((f: any) => f.key)
    ].toString(),
    children: [
        ...fields.ticket_parent,
        {
            label: 'Travel Agency',
            showFor: { entity: [airlineEntity, agencyEntity] },
            key: fields["Travel Agency"].map((f: any) => f.key).toString(),
            children: [...fields["Travel Agency"]],
        },
        // {
        //     label: 'Ticketing Airline',
        //     showFor: { entity: [airlineEntity, agencyEntity] },
        //     key: fields["Ticketing Airline"].map((f: any) => f.key).toString(),
        //     children: [...fields["Ticketing Airline"]],
        // }, 
        {
            label: 'Point of Origin Airport',
            key: fields["Point of Origin Airport"].map((f: any) => f.key).toString(),
            children: [...fields["Point of Origin Airport"]],
        }, {
            label: 'Details for Exchanges, Refunds, and other Transactions',
            key: fields["Details for Exchanges, Refunds, and other Transactions"].map((f: any) => f.key).toString(),
            children: [...fields["Details for Exchanges, Refunds, and other Transactions"]],
        }, {
            label: 'Others',
            showFor: { subscription: ticketSubscription },
            key: fields["Others"].map((f: any) => f.key).toString(),
            children: [...fields["Others"]],
        }
    ]
}

const groupRCs = {
    label: 'Group Fields',
    showFor: { entity: [airlineEntity, agencyEntity] },
    key: [
        fields.group_parent.map((f: any) => f.key)
    ].toString(),
    children: [
        ...fields.group_parent
    ]
}

export const INTERACTIVETemplate: any = {
    reportName: "INTERACTIVE",
    info: "Use the below options of data metrics, row and column data elements, and query filters to configure your desired report template. The combination of selections will be used to calculate your report data and offer summarized or daily metrics which can be queried using a combination of both travel and purchase periods.",
    lists: [
        {
            label: "General",
            colorName: "",
            wraps: [{
                name: "Airline Information",
                isTitle: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                showName: false,
                fields: []
            }, {
                name: "Airline Code Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                excludable: true,
                checks: aggt,
                fields: [{
                    name: "airline",
                    label: "Airline Code",
                    rLabel: "Airline code",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 0,
                    color: 'ap',
                    outputLabel: true,
                    url: window.ENV.references.airline,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airline) || '[]'),
                    placeholder: "Enter Airline(s)"
                }]
            }, {
                name: "Airline Type",
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                showName: false,
                fields: [{
                    name: "airline_role",
                    label: "Airline Type",
                    help: "Select one or a combination of airlines types to correspond with the airline criteria entered.",
                    type: "typeahead",
                    labelKey: "value",
                    codeKey: "key",
                    hideKey: true,
                    minChar: 0,
                    color: 'c',
                    matchedOnly: true,
                    required: true,
                    selectAll: true,
                    value: [{ key: "dom_mkt_al" }],
                    options: [
                        { key: "dom_mkt_al", value: "Dominant Marketing Airlines", checked: true },
                        { key: "dom_opt_al", value: "Dominant Operating Airlines", checked: true },
                        { key: "tkt_al", value: "Ticketing Airlines", checked: true },
                        { key: "poo_mkt_al", value: "Point of Origin Marketing Airlines", checked: true },
                        { key: "mkt_al_any_segment", value: "Marketing Airlines on any Segment", checked: true, showFor: { subscription: segmentSubscription } },
                        { key: "opt_al_any_segment", value: "Operating Airlines on any Segment", checked: true, showFor: { subscription: segmentSubscription } },
                        { key: "seg_mkt_al", value: "Segment Marketing Airline", checked: true, showFor: { subscription: segmentSubscription } },
                        { key: "seg_opt_al", value: "Segment Operating Airline", checked: true, showFor: { subscription: segmentSubscription } }
                    ],
                    placeholder: "Enter Airline Type(s)"
                }]
            }, {
                name: "Point Of Origin",
                help: "Enter the Point of Origin to query where a passenger's travel initiates with respect to the entire ticket.",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                excludable: true,
                fields: [{
                    name: "poo_org",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Point of Origin Codes",
                    hLabel: "Point of Origin",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    disableFor: { entity: [financialEntity] },
                    show: true,
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Point(s) of Origin"
                }, {
                    name: "poo_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Point of Origin Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: "r",
                    minChar: 2,
                    disableFor: { entity: [] },
                    show: false,
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Point(s) of Origin"
                }, {
                    name: "poo_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Point of Origin City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "c",
                    minChar: 1,
                    disableFor: { entity: [financialEntity] },
                    show: false,
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Point(s) of Origin"
                }]
            }, {
                name: "Directionality",
                showName: false,
                fields: [{
                    name: "directionality",
                    label: "Directionality",
                    help: 'Select "Directional" to limit output to one direction, from origin(s) to destination(s). Select "Bi-directional" to display output for both directions of travel separately, or “Bi-directional Total” to sum both directions together. Select "Non-directional" to display the average from both directions of travel into one output row.',
                    type: "select",
                    noSelect: true,
                    value: 'DIRECTIONAL',
                    options: [
                        { key: "DIRECTIONAL", value: "Directional", checked: true },
                        { key: "NON_DIRECTIONAL", value: "Non-Directional", checked: true },
                        { key: "BI_DIRECTIONAL", value: "Bi-Directional", checked: true },
                        { key: "BI_DIRECTIONAL_TOTAL", value: "Bi-Directional Total", checked: true },
                    ]
                }]
            }, {
                name: "Transaction Type",
                showName: false,
                fields: [{
                    name: "transaction_code",
                    label: "Transaction Type",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "E", value: "Exchange", checked: true },
                        { key: "O", value: "Old Ticket", checked: true, enableFor: { entity: [airlineEntity] } },
                        { key: "V", value: "Void", checked: true, enableFor: { dataSource: GLOBALDATA } },
                        { key: "R", value: "Refund", checked: true },
                        { key: "I", value: "Sales", checked: true }
                    ],
                    placeholder: "Enter Transaction Type(s)"
                }]
            }, {
                name: "ndc",
                showName: false,
                showFor: { subscription: ndcSubscription },
                checks: ndcSubscription,
                fields: [{
                    name: "ndc",
                    label: "NDC Indicator",
                    help: 'Include or exclude NDC Indicator.',
                    type: "select",
                    noSelect: false,
                    value: "",
                    options: [
                        { key: "Y", value: "Yes", checked: true },
                        { key: "N", value: "No", checked: true }
                    ]
                }]
            }, {
                name: "Trip Purpose",
                fields: [{
                    name: "trip_purpose",
                    label: "Trip Purpose",
                    type: "typeahead",
                    matchedOnly: false,
                    labelKey: "value",
                    codeKey: "key",
                    minChar: 0,
                    color: 'ap',
                    canEmpty: true,
                    selectAll: true,
                    value: [],
                    options: [
                        { key: "B", value: "Business", checked: true },
                        { key: "L", value: "Leisure", checked: true }
                    ],
                    help: "Derived by ARC-generated algorithm.",
                    placeholder: "Enter Trip Purpose"
                }]
            }, {
                name: "Agency Information",
                help: "Enter complete 8-digit agency or HOL numbers, separated by commas. Leave blank to view agencies located in a city, state, postal code or region.",
                isTitle: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                showName: false,
                lookup: "agency",
                fields: []
            }, {
                name: "Agency or HOL",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                type: 'option',
                condition: 'OR',
                excludable: true,
                disableEmpty: true,
                fields: [{
                    name: "agency",
                    tab: "Agency",
                    label: "By Agency",
                    rLabel: "Agency",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'ap',
                    minChar: 0,
                    show: true,
                    outputLabel: true,
                    isAgencyTop: true,
                    options: [],
                    url: window.ENV.references.agency,
                    placeholder: "Enter Agency Code(s) / Group(s)"
                }, {
                    name: "hol",
                    tab: "Home Office Location",
                    label: "By Home Office Location",
                    rLabel: "Home Office Location",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'c',
                    show: false,
                    outputLabel: true,
                    isAgencyTop: true,
                    minChar: 0,
                    options: [],
                    url: window.ENV.references.agency,
                    placeholder: "Enter Home Office Location Code(s) / Group(s)"
                }]
            }, {
                name: "Agency Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "agency_city",
                    label: "City",
                    type: "text",
                    value: ""
                }, {
                    name: "agency_postal",
                    label: "Postal Code",
                    type: "text",
                    value: ""
                }]
            }, {
                name: "Agency Region Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "agency_region",
                    label: "State / Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: 'r',
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]')
                }]
            }, {
                name: "Date Information",
                help: "Select date periods from either purchase, travel or a combination of both date types using the below options.",
                isTitle: true,
                showName: false,
                fields: [],
            }, {
                name: "Purchase Period",
                help: "FROM date should be previous or current to the TO date",
                showName: true,
                type: 'option',
                condition: 'OR',
                fields: [{
                    name: "purchase_all_report_period",
                    label: "All",
                    rLabel: "All Purchase Periods",
                    hLabel: "Purchase Periods",
                    type: "radio",
                    show: true,
                    value: 'all',
                    options: [{ key: "all", value: "All Purchase Periods", disabled: true }]
                }, {
                    name: "purchase_day_report_period",
                    tab: "By Day",
                    label: "By Day or Range",
                    hLabel: "Purchase Period (D)",
                    type: "date",
                    selectsRange: true,
                    startDate: [FDS.dateToText(new Date(), "/", true, false)],
                    endDate: [FDS.dateToText(new Date(), "/", true, false)],
                    show: false
                }, {
                    name: "purchase_month_report_period",
                    tab: "By Month",
                    label: "By Month or Range",
                    hLabel: "Purchase Period (M)",
                    type: "monthYearPicker",
                    selectsRange: true,
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "purchase_quarter_report_period",
                    tab: "By Quarter",
                    label: "By Quarter or Range",
                    hLabel: "Purchase Period (Q)",
                    type: "quarterPicker",
                    selectsRange: true,
                    startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "purchase_year_report_period",
                    tab: "By Year",
                    label: "By Year or Range",
                    hLabel: "Purchase Period (Y)",
                    type: "yearPicker",
                    selectsRange: true,
                    startDate: [new Date().getFullYear().toString()],
                    endDate: [new Date().getFullYear().toString()],
                    show: false
                }, {
                    name: "purchase_summary_report_period",
                    tab: "12M Sum",
                    label: "12 Month Summary Ending",
                    hLabel: "Purchase Period (12M)",
                    type: "monthYearPicker",
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [''],
                    show: false
                }, {
                    name: "purchase_compare_m_report_period",
                    tab: "Compare(M)",
                    label: "Compare Months",
                    hLabel: "Purchase Period (Compare - M)",
                    compare: true,
                    selectsRange: true,
                    type: "monthYearPicker",
                    startDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "purchase_compare_q_report_period",
                    tab: "Compare(Q)",
                    label: "Compare Quarters",
                    hLabel: "Purchase Period (Compare - Q)",
                    compare: true,
                    selectsRange: true,
                    type: "quarterPicker",
                    startDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "purchase_compare_y_report_period",
                    tab: "Compare(Y)",
                    label: "Compare 2 Years",
                    hLabel: "Purchase Period (Compare - Y)",
                    compare: true,
                    type: "yearPicker",
                    startDate: [(new Date().getFullYear() - 1) + "", new Date().getFullYear() + ''],
                    endDate: [],
                    show: false
                }]
            }, {
                name: "Travel Period",
                help: "FROM date should be previous or current to the TO date",
                showName: true,
                type: 'option',
                condition: 'OR',
                fields: [{
                    name: "travel_all_report_period",
                    label: "All",
                    rLabel: "All Travel Periods",
                    hLabel: "Travel Periods",
                    type: "radio",
                    show: true,
                    value: 'all',
                    options: [{ key: "all", value: "All Travel Periods", disabled: true }]
                }, {
                    name: "travel_day_report_period",
                    tab: "By Day",
                    label: "By Day or Range",
                    hLabel: "Travel Period (D)",
                    type: "date",
                    selectsRange: true,
                    startDate: [FDS.dateToText(new Date(), "/", true, false)],
                    endDate: [FDS.dateToText(new Date(), "/", true, false)],
                    show: false
                }, {
                    name: "travel_month_report_period",
                    tab: "By Month",
                    label: "By Month or Range",
                    hLabel: "Travel Period (M)",
                    type: "monthYearPicker",
                    selectsRange: true,
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "travel_quarter_report_period",
                    tab: "By Quarter",
                    label: "By Quarter or Range",
                    hLabel: "Travel Period (Q)",
                    type: "quarterPicker",
                    selectsRange: true,
                    startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "travel_year_report_period",
                    tab: "By Year",
                    label: "By Year or Range",
                    hLabel: "Travel Period (Y)",
                    type: "yearPicker",
                    selectsRange: true,
                    startDate: [new Date().getFullYear().toString()],
                    endDate: [new Date().getFullYear().toString()],
                    show: false
                }, {
                    name: "travel_summary_report_period",
                    tab: "12M Sum",
                    label: "12 Month Summary Ending",
                    hLabel: "Travel Period (12M)",
                    type: "monthYearPicker",
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [''],
                    show: false
                }, {
                    name: "travel_compare_m_report_period",
                    tab: "Compare(M)",
                    label: "Compare Months",
                    hLabel: "Travel Period (Compare - M)",
                    compare: true,
                    selectsRange: true,
                    type: "monthYearPicker",
                    startDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "travel_compare_q_report_period",
                    tab: "Compare(Q)",
                    label: "Compare Quarters",
                    hLabel: "Travel Period  (Compare - Q)",
                    compare: true,
                    selectsRange: true,
                    type: "quarterPicker",
                    startDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "travel_compare_y_report_period",
                    tab: "Compare(Y)",
                    label: "Compare 2 Years",
                    hLabel: "Travel Period  (Compare - Y)",
                    compare: true,
                    type: "yearPicker",
                    startDate: [(new Date().getFullYear() - 1) + "", new Date().getFullYear() + ''],
                    endDate: [],
                    show: false
                }]
            }, {
                name: "Other Information",
                isTitle: true,
                showName: false,
                fields: []
            }, {
                name: "countriesOfSale",
                showName: false,
                fields: [{
                    name: "pos_country",
                    label: "Countries Of Sale",
                    rLabel: "Countries of Sale",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    disabled: true,
                    enableFor: { dataSource: GLOBALDATA },
                    show: true,
                    url: window.ENV.references.country,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]')
                }]
            }, {
                name: "Data Source",
                showName: false,
                fields: [{
                    name: "source",
                    label: "Data Source",
                    rlabel: "Data Source",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    hideKey: true,
                    required: true,
                    minChar: 0,
                    show: true,
                    selectAll: true,
                    disabled: true,
                    enableFor: { dataSource: GLOBALDATA },
                    options: [
                        { code: "1", description: "ASP Settled Data" },
                        { code: "2", description: "Contributed Direct" },
                        { code: "3", description: "BSP Settled Data" },
                        { code: "4", description: "Contributed Indirect" }
                    ],
                    placeholder: "Select Data Source(s)"
                }]
            }]
        },
        {
            label: "Segment",
            colorName: "",
            showFor: { subscription: segmentSubscription },
            wraps: [{
                name: "Segment Origin",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                fields: [{
                    name: "o_segment",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Segment Origin Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    clearOnSelect: false,
                    noTop: true,
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Single Origin Airport"
                }, {
                    name: "o_segment_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Segment Origin Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 2,
                    color: 'r',
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_segment_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Segment Origin City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 1,
                    color: 'c',
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Origin"
                }]
            }, {
                name: "Segment Destination",
                showName: true,
                type: 'option',
                condition: 'AND',
                excludable: true,
                fields: [{
                    name: "d_segment",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Segment Destination Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 2,
                    color: 'ap',
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_segment_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Segment Destination Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 2,
                    color: 'r',
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_segment_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Segment Destination City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 1,
                    color: 'c',
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Destination(s)"
                }]
            }, {
                name: "Segment Service Class",
                showName: false,
                fields: [{
                    name: "segment_cabin_class",
                    label: "Segment Service Class",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "First Class", value: "First Class", checked: true },
                        { key: "Business Class", value: "Business Class", checked: true },
                        { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                        { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                        { key: "Other Classes", value: "Other Classes", checked: true }
                    ],
                    placeholder: "Select"
                }]
            }, {
                name: "Segment Ticketing Designator",
                showName: false,
                fields: [{
                    name: "segment_rbkd",
                    label: "Segment Ticketing Designator",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "A", value: "A", checked: true },
                        { key: "B", value: "B", checked: true },
                        { key: "C", value: "C", checked: true },
                        { key: "D", value: "D", checked: true },
                        { key: "E", value: "E", checked: true },
                        { key: "F", value: "F", checked: true },
                        { key: "G", value: "G", checked: true },
                        { key: "H", value: "H", checked: true },
                        { key: "I", value: "I", checked: true },
                        { key: "J", value: "J", checked: true },
                        { key: "K", value: "K", checked: true },
                        { key: "L", value: "L", checked: true },
                        { key: "M", value: "M", checked: true },
                        { key: "N", value: "N", checked: true },
                        { key: "O", value: "O", checked: true },
                        { key: "P", value: "P", checked: true },
                        { key: "Q", value: "Q", checked: true },
                        { key: "R", value: "R", checked: true },
                        { key: "S", value: "S", checked: true },
                        { key: "T", value: "T", checked: true },
                        { key: "U", value: "U", checked: true },
                        { key: "V", value: "V", checked: true },
                        { key: "W", value: "W", checked: true },
                        { key: "X", value: "X", checked: true },
                        { key: "Y", value: "Y", checked: true },
                        { key: "Z", value: "Z", checked: true }
                    ],
                    placeholder: "Select"
                }]
            }, {
                name: "Share",
                showName: false,
                fields: [{
                    name: "share",
                    label: "Share (Greater Than)",
                    help: "Used to prune data where the market share % is less than the chosen value. Data which has been pruned is aggregated into the OTHER row.",
                    type: "select",
                    noSelect: true,
                    value: "0",
                    options: [
                        { key: '5', value: '5.0%', checked: true },
                        { key: '1', value: '1.0%', checked: true },
                        { key: '0.5', value: '0.5%', checked: true },
                        { key: '0.25', value: '0.25%', checked: true },
                        { key: '0.125', value: '0.125%', checked: true },
                        { key: '0', value: '0.0%', checked: true }
                    ]
                }]
            }]
        },
        {
            label: "Trip",
            colorName: "",
            wraps: [{
                name: "Trip Origin",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                excludable: true,
                fields: [{
                    name: "org",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Origin Codes",
                    hLabel: "Trip Origin",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Origin Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: "r",
                    minChar: 2,
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Origin City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "c",
                    minChar: 1,
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Origin(s)"
                }]
            }, {
                name: "Trip Destination",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                excludable: true,
                fields: [{
                    name: "dst",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Destination Codes",
                    hLabel: "Trip Destination",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 2,
                    color: 'ap',
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Destination Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 2,
                    color: 'r',
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Destination City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 1,
                    color: 'c',
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Destination(s)"
                }]
            }, {
                name: "Connect Point Code",
                showName: false,
                excludable: true,
                hideFor: { entity: [financialEntity] },
                fields: [{
                    name: "cpc_org",
                    tab: "Airport",
                    label: "Connect Point Code (Airport)",
                    help: "Enter one or more 3-character airport codes to specify connections, separated by commas. ",
                    rLabel: "Connect Point Code",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    show: true,
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Connect Point(s)"
                }]
            }, {
                name: "Markets",
                showName: true,
                requiredGroup: 1,
                hideFor: { entity: [financialEntity] },
                help: "Enter market criteria by concatenating two airport codes, i.e. DCAJFK. Multiple markets can be entered separated by commas.",
                fields: [{
                    name: "markets",
                    label: "Markets",
                    hideLabel: true,
                    type: "typeahead",
                    matchedOnly: false,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    color: 'ap',
                    show: true,
                    canEmpty: true,
                    store: "groupingsDataRef",
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:apap") || '[]'),
                    placeholder: "Enter Market(s)"
                }]
            }, {
                name: "Trip Service Class",
                showName: false,
                fields: [{
                    name: "trip_cabin_class",
                    label: "Trip Service Class",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "First Class", value: "First Class", checked: true },
                        { key: "Business Class", value: "Business Class", checked: true },
                        { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                        { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                        { key: "Other Classes", value: "Other Classes", checked: true }
                    ],
                    placeholder: "Select"
                }]
            }, {
                name: "Trip Ticketing Designator",
                showName: false,
                fields: [{
                    name: "trip_rbkd",
                    label: "Trip Ticketing Designator",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "A", value: "A", checked: true },
                        { key: "B", value: "B", checked: true },
                        { key: "C", value: "C", checked: true },
                        { key: "D", value: "D", checked: true },
                        { key: "E", value: "E", checked: true },
                        { key: "F", value: "F", checked: true },
                        { key: "G", value: "G", checked: true },
                        { key: "H", value: "H", checked: true },
                        { key: "I", value: "I", checked: true },
                        { key: "J", value: "J", checked: true },
                        { key: "K", value: "K", checked: true },
                        { key: "L", value: "L", checked: true },
                        { key: "M", value: "M", checked: true },
                        { key: "N", value: "N", checked: true },
                        { key: "O", value: "O", checked: true },
                        { key: "P", value: "P", checked: true },
                        { key: "Q", value: "Q", checked: true },
                        { key: "R", value: "R", checked: true },
                        { key: "S", value: "S", checked: true },
                        { key: "T", value: "T", checked: true },
                        { key: "U", value: "U", checked: true },
                        { key: "V", value: "V", checked: true },
                        { key: "W", value: "W", checked: true },
                        { key: "X", value: "X", checked: true },
                        { key: "Y", value: "Y", checked: true },
                        { key: "Z", value: "Z", checked: true }
                    ],
                    placeholder: "Select"
                }]
            }]
        },
        {
            label: "Ticket",
            colorName: "",
            showFor: { subscription: ticketSubscription },
            wraps: [{
                name: "Ticket / Document Numbers",
                showName: true,
                requiredGroup: 1,
                fields: [{
                    name: "ticket",
                    label: "Ticket / Document Numbers",
                    hideLabel: true,
                    value: [],
                    show: true,
                    placeholder: "Enter Ticket / Document Numbers",
                    type: "typeahead",
                    matchedOnly: false,
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    color: 'ap',
                    canEmpty: true,
                    options: []
                }]
            }, {
                name: "PNR Codes",
                requiredGroup: 1,
                showName: true,
                fields: [{
                    name: "pnr",
                    label: "PNR Codes",
                    hideLabel: true,
                    type: "typeahead",
                    matchedOnly: false,
                    labelKey: "name",
                    codeKey: "code",
                    value: [],
                    minChar: 0,
                    color: 'ap',
                    canEmpty: true,
                    options: [],
                    placeholder: "Enter PNR Codes"
                }]
            }, {
                name: "Tour Code",
                showName: false,
                fields: [{
                    name: "tour_code",
                    label: "Tour Code",
                    type: "text",
                    value: [],
                    show: true,
                    placeholder: "Enter Tour Code"
                }]
            }, {
                name: "GDS/System Provider",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "gds",
                    label: "GDS/System Provider",
                    type: "text",
                    value: [],
                    show: true,
                    maxLength: 4,
                    help: "4-digit code found in the ‘System Provider Column’ in the IDv5 file",
                    placeholder: "Enter GDS/System Provider"
                }]
            }, {
                name: "Domestic / International",
                help: "Select one or all options below.",
                showName: true,
                fields: [{
                    name: "domesticorinternational",
                    label: "All",
                    type: "toggleCheckbox",
                    withCheckbox: true,
                    checked: false,
                    collapse: false,
                    value: [
                        { domestic: false },
                        { international: false }
                    ],
                    options: [
                        { key: "domestic", value: "Domestic", checked: true },
                        { key: "international", value: "International", checked: true },
                    ]
                }]
            }]
        },
        {
            label: "Data Metrics",
            required: true,
            colorName: "success",
            info: "Select at least one or more data metrics to include in the report output.",
            wraps: [{
                name: "Data Metrics",
                showName: true,
                required: true,
                fields: [{
                    name: "data_metrics",
                    label: "Data Metrics",
                    hideLabel: true,
                    labelKey: "label",
                    codeKey: "key",
                    type: "tree",
                    treeChildrenCheck: true,
                    onlyKeyCheck: true,
                    value: [],
                    options: dataMetricFields,
                    placeholder: "Select Data Metrics"
                }]
            }]
        },
        {
            label: "Row Fields",
            requiredGroup: 2,
            colorName: "info",
            mergeOnIndex: 6,
            info: "Select one or more data elements to include as rows in the report template. The same data element cannot be selected in both columns and rows.",
            wraps: [{
                name: "Row Fields",
                showName: true,
                fields: [{
                    name: "rows",
                    label: "Rows",
                    hideLabel: true,
                    labelKey: "label",
                    codeKey: "key",
                    type: "tree",
                    treeChildrenCheck: true,
                    onlyKeyCheck: true,
                    value: [],
                    options: [generalRCs, segmentRCs, tripRCs, ticketRCs, groupRCs, proprietaryFields],
                    placeholder: "Select Row Fields"
                }]
            }]
        },
        {
            label: "Column Fields",
            requiredGroup: 2,
            colorName: "warning",
            mergeOnIndex: 5,
            info: "Select one or more data elements to include as columns in the report template. The same data element cannot be selected in both columns and rows.",
            wraps: [{
                name: "Column Fields",
                showName: true,
                fields: [{
                    name: "cols",
                    label: "Cols",
                    hideLabel: true,
                    labelKey: "label",
                    codeKey: "key",
                    type: "tree",
                    treeChildrenCheck: true,
                    onlyKeyCheck: true,
                    value: [],
                    options: [generalRCs, segmentRCs, tripRCs, ticketRCs, groupRCs, proprietaryFields],
                    placeholder: "Select Column Fields"
                }]
            }]
        }
    ]
};